import React, { PureComponent, useContext } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import FormControl from "@material-ui/core/FormControl";
import CardContent from "@material-ui/core/CardContent";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import SearchIcon from "@material-ui/icons/Search";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import InputLabel from "@material-ui/core/InputLabel";
import LaunchIcon from "@material-ui/icons/Launch";
import LastPageIcon from "@material-ui/icons/LastPage";
import InputBase from "@material-ui/core/InputBase";
import { fade } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { axios } from "../../utils/axiosConfig";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ConfirmedSnackBar from "../../utils/ConfirmedSnackbar";
import { UserContextData, UserContext } from "../../Contexts/userContext";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
// import JobDetail from "./JobDetail";
import TablePagination from "@material-ui/core/TablePagination";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker
} from "@material-ui/pickers";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Pagination from "@material-ui/lab/Pagination";

import TableHead from "@material-ui/core/TableHead";

import Typography from "@material-ui/core/Typography";
import Input from "@material-ui/core/Input";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import Switch from "@material-ui/core/Switch";
import Chip from "@material-ui/core/Chip";
import SaveIcon from "@material-ui/icons/Save";
import moment from "moment-timezone";
import Card from "@material-ui/core/Card";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import PaymentSubs from "./PaymentSubs";
import PaymentChart from "./PaymentChart";
import PaymentTable from "./PaymentTable";
const styles = {
  textField: {
    // width: "100%",
    marginLeft: "5px",
    marginRight: "5px"
  },
  selectFeild: {
    width: "15%",
    marginTop: "15px",
    marginLeft: "5px",
    marginRight: "5px"
  },
  grid: {
    padding: "16px"
  },
  tableHeadFull: {
    width: "100%"
  },
  datePicker: {
    selectColor: "black"
  },
  priceTextFeild: {
    margin: "26px"
  },
  jobCardPrice: {
    color: "#fff",
    fontSize: "52px",
    marginTop: "7px",
    marginBottom: "7px"
  },
  formControl: {
    width: "65%"
  },
  center: {
    margin: "auto",
    width: "fit-content"
  },
  search: {
    position: "relative",
    borderRadius: "4px",
    backgroundColor: fade("#fff", 0.15),
    "&:hover": {
      backgroundColor: fade("#fff", 0.25)
    },
    // marginLeft: 0,
    width: "250px",
    marginLeft: "auto"
  },
  searchIcon: {
    padding: "16px",
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "inherit"
  },
  inputInput: {
    padding: "8px, 8px, 8px, 8px",
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + 32px)`,
    width: "100%"
  },
  card: {
    width: "100%",
    backgroud: "rgba(35,35,35, 0.9); !important"
  },
  jobCardTitle: {
    marginTop: "10px",
    color: "#fff"
  },
  titleDiv: {
    display: "flex"
  },
  switchGrid: {
    margin: "auto",
    display: "flex"
  },
  switchClass: {
    margin: "3px 10px 5px 10px"
  },
  timeContainer: {
    margin: "43px 0px 0px 0px"
  },
  rootCard: {
    background: "rgba(35,35,35, 0.9)",
    width: "100%",
    marginRight: "80px"
  },
  showCircularProgressStyle: {
    margin: "auto",
    width: "fit-content",
    padding: "50%"
  },

  tableHeading: {
    padding: "15px",
    fontSize: "20px",
    color: "#fff",
    display: "flex",
    background: "rgba(35,35,35, 0.9);"
  },
  tableBold: {
    fontWeight: 600,
    fontSize: "17px",
    color: "rgba(35,35,35, 0.9);"
  },

  gridHere: {
    // borderRight:"1px solid rgba(35,35,35, 0.9);"
  },

  editIcon: {
    color: "#fff",
    fontSize: "16px",
    cursor: "pointer"
    // marginLeft: "20px",
    // marginBottom: "15px"
  },

  expandIcon: {
    color: "#fff",
    fontSize: "20px",
    cursor: "pointer"
    // marginLeft: "20px",
    // marginBottom: "15px"
  },
  launchIcon: {
    color: "rgba(35,35,35, 0.9);",
    cursor: "pointer"
  },
  oilIcons: {
    fontSize: "40px",
    color: "#fff",
    margin: "4px 4px"
  },
  priceIcons: {
    fontSize: "50px",
    color: "#fff",
    margin: "4px 4px"
  },
  editIconChip: {
    fontSize: "16px",
    cursor: "pointer"
  },
  tableCellClass: {
    display: "inline-flex"
  },
  inputFeildHovered: {
    color: "#fff",
    fontSize: "16px",
    width: "380px",
    background: "none",
    textAlign: "center",
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none"
    }
  },
  inputFeild: {
    color: "rgba(35,35,35, 0.9);",
    fontSize: "16px",
    width: "380px",
    background: "none",
    textAlign: "center",
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none"
    }
  },
  inputFeildHaulage: {
    color: "rgba(35,35,35, 0.9);",
    fontSize: "20px",
    background: "none",
    width: "40px",
    textAlign: "center",
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none"
    }
  },
  inputFeildHaulageHovered: {
    color: "#fff",
    fontSize: "20px",
    background: "none",
    width: "40px",
    textAlign: "center",
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none"
    }
  },

  startAutoJob: {
    color: "#fff",
    background: "rgba(35,35,35, 0.9);",
    width: "auto",
    height: "53px",
    marginTop: "17px",
    marginLeft: "10px",
    fontSize: "16px",
    "&:hover": {
      backgroundColor: "rgba(35,35,35, 0.9);",
      color: "#fff"
    }
  },
  startCron: {
    marginLeft: "auto",
    marginTop: "auto",
    marginBottom: "auto"
  },
  tablePagination: {
    marginLeft: "auto"
  },
  enabledChip: {
    backgroudColor: "green"
  },
  disabledChip: {
    backgroud: "red"
  },
  "& .MuiTablePagination-toolbar": {
    marginLeft: "auto"
  },
  tableFooter: {
    width: "fit-content",
    marginTop: "20px",
    marginLeft: "auto"
  },
  circularProgress: {
    marginLeft: "10px"
  },
  circularProgressCenter: {
    margin: "auto"
  },
  autoCircularProgress: {
    margin: "auto"
  },
  circularStyle: {
    width: "100%",
    margin: "auto",
    marginTop: "15%",
    marginBottom: "15%"
  },
  circularProgressBase: {
    width: "100%"
  },
  snackbar: {
    backgroundColor: "rgb(50, 50, 50)"
  },
  fullWidth: {
    width: "100%"
  },
  close: {
    color: "#fff"
  },
  menuButtonsIconPrimary: {
    marginLeft: "auto",
    color: "#fff",
    cursor: "pointer"
    // height: "50px"
  },
  dialogContent: {
    margin: "10px"
  },
  newCronTimePicker: {
    marginTop: "15px"
  },
  headerStyle: {
    display: "flex",
    width: "100%"
  },
  autoComplete: {
    width: "20%",
    marginRight: "10px",
    marginTop: "15px",
    /* display: flex; */

    display: "inline-flex",
    position: "relative",
    minWidth: 0,
    flexDirection: "column",
    verticalAlign: "top"
  },
  subHeading: {
    fontSize: "15px",
    fontWeight: 600
  },
  noDataFoundText: {
    color: "rgba(35,35,35, 0.9)",
    fontSize: "1rem",
    marginBottom: "7px",
    marginTop: "7px",
    margin: "auto",
    textAlign: "center",
    fontWeight: "600",
    // letterSpacing: "0.1rem",
    lineHeight: 1.5
  },
  tableRowMain:{
    "&:hover": {
    border: "2px solid #f1b640",
    transition: "box-shadow 0.3s ease",
    boxShadow: "0 5px 10px rgb(77 83 88 / 23%)"
  }
}
};

class Payments extends PureComponent {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      openSnackbar: false,
      snackBarMessage: null,
      showCircularProgress1: false,
      showCircularProgress2: false,
      tableColumns: [
        {
          field: "invoiceNumber",
          title: "Invoice Number"
        },
        {
          field: "customerName",
          title: "Customer Name"
        },
        {
          field: "paymentType",
          title: "Payment Type"
        },
        {
          field: "totalAmount",
          title: "Total Amount"
        },
        {
          field: "amountPaid",
          title: "Amount Paid"
        },
        {
          field: "changeGiven",
          title: "Change Given"
        },

        {
          field: "owedAmount",
          title: "Owed Amount"
        },
        {
          field: "addedAt",
          title: "Payment Added at"
        },

        {
          field: "driverName",
          title: "Driver Name"
        },
        {
          field: "paymentConfirmed",
          title: "Payment Confirmed"
        },
        {
          field: "paymentConfirmedBy",
          title: "Payment Confirmed By"
        }
      ],
      paymentConfirmed:"",
      totalPages: 1,
      filters: "",
      filterPage: 1,
      pageSize: 10,
      status: "",
      deliveryBy: moment().subtract(30, "days"),
      // deliveryBy: moment(),
      deliveryFrom: moment().add(1, "days"),
      // deliveryFrom: moment(),
      jobID: "",
      customerAccountRef: "",
      orderNumber: "",
      invoiceNumber: "",
      driverName: "",
      paymentType: "",
      customerName: "",
      product: "",
      quantity: "",
      vehicleRegNo: "",
      paymentListData: [],
      paymentListFilteredData: [],
      allDrivers: [],
      allProducts: [],
      allVehicles: [],

      jobDetailView: false,
      selectedJob: {},

      countOfPayments: "",
      countOfPaymentsByLastWeek: [],
      paymentsForChartList: [],
      sumOfPayments: "",
      sumOfPaymentsByDriver: [],
      sumOfPaymentsByLastWeek: [],
      sumOfPaymentsByType: [],

      sumOfPaymentsByDriverChartOptions: {},
      sumOfPaymentsByTypeChartOptions: {},

      totalPaymentsChartOption: {},

      countOfPaymentsByLastWeekChartOption: {},
      sumOfPaymentsByLastWeekChartOption: {}
    };
  }

  componentDidMount() {
    this.allPayments(1);
    this.paymentSummary(1);
    this.getAllDrivers();
    this.getAllProducts();
    this.getAllVehicles();
  }

  allPayments = pageNumber => {
    const { logoutUser } = this.props;
    this.setState({ showCircularProgress1: true });
    const {
      filterPage,
      pageSize,
      status,
      deliveryBy,
      deliveryFrom,
      jobID,
      customerAccountRef,
      orderNumber,
      invoiceNumber,
      driverName,
      paymentConfirmed,
      paymentType,
      customerName,
      product,
      quantity,
      vehicleRegNo
    } = this.state;

    let url = `job/payments/all?page=${pageNumber}&size=${pageSize}${
      jobID !== "" ? `&jobID=${jobID}` : ""
    }${
      customerAccountRef !== ""
        ? `&customerAccountRef=${customerAccountRef}`
        : ""
    }${
      orderNumber !== ""
        ? `&orderNumber=${encodeURIComponent(orderNumber)}`
        : ""
    }${customerName !== "" ? `&customerName=${customerName}` : ""}${
      deliveryBy !== ""
        ? `&dateFrom=${moment(deliveryBy)
            .tz("Europe/London")
            .format("YYYY-MM-DD")}`
        : ""
    }${
      deliveryFrom !== ""
        ? `&dateTo=${moment(deliveryFrom)
            .tz("Europe/London")
            .format("YYYY-MM-DD")}`
        : ""
    }${driverName !== "" ? `&driverName=${driverName}` : ""}${
      invoiceNumber !== "" ? `&invoiceNumber=${invoiceNumber}` : ""
    }${
      paymentConfirmed !== "" ? `&paymentConfirmed=${paymentConfirmed}` : ""
    }${paymentType !== "" ? `&paymentType=${paymentType}` : ""}${
      quantity !== "" ? `&quantity=${quantity.toString()}` : ""
    }${status !== "" ? `&status=${status}` : ""}${
      vehicleRegNo !== "" ? `&vehicleRegNo=${vehicleRegNo}` : ""
    }`;

    axios
      .get(url)
      .then(res => {
        let response = res.data;
        console.log(res, "reshere");
        if (response.success === true) {
          this.setState({ totalPages: response.data.totalPages });
          this.setState({
            paymentListData: response.data.payments,
            paymentListFilteredData: response.data.payments
          });
        } else {
          this.setState({ totalPages: 1 });
          this.setState({
            paymentListData: [],
            paymentListFilteredData: []
          });
        }
        this.setState({ showCircularProgress1: false });
      })
      .catch(function(error) {
        if (error.response.status === 403) {
          logoutUser();
        }
      });
  };

  paymentSummary = pageNumber => {
    const { logoutUser } = this.props;
    this.setState({ showCircularProgress2: true });
    const {
      filterPage,
      pageSize,
      status,
      deliveryBy,
      deliveryFrom,
      jobID,
      customerAccountRef,
      orderNumber,
      invoiceNumber,
      driverName,
      paymentType,
      paymentConfirmed,
      customerName,
      product,
      quantity,
      vehicleRegNo
    } = this.state;

    console.log(product, "productHere");
    let url = `job/payments/summary?page=${pageNumber}&size=${pageSize}${
      jobID !== "" ? `&jobID=${jobID}` : ""
    }${
      customerAccountRef !== ""
        ? `&customerAccountRef=${customerAccountRef}`
        : ""
    }${
      paymentConfirmed !== "" ? `&paymentConfirmed=${paymentConfirmed}` : ""
    }${
      orderNumber !== ""
        ? `&orderNumber=${encodeURIComponent(orderNumber)}`
        : ""
    }${customerName !== "" ? `&customerName=${customerName}` : ""}${
      deliveryBy !== ""
        ? `&dateFrom=${moment(deliveryBy)
            .tz("Europe/London")
            .format("YYYY-MM-DD")}`
        : ""
    }${
      deliveryFrom !== ""
        ? `&dateTo=${moment(deliveryFrom)
            .tz("Europe/London")
            .format("YYYY-MM-DD")}`
        : ""
    }${driverName !== "" ? `&driverName=${driverName}` : ""}${
      invoiceNumber !== "" ? `&invoiceNumber=${invoiceNumber}` : ""
    }${paymentType !== "" ? `&paymentType=${paymentType}` : ""}${
      quantity !== "" ? `&quantity=${quantity.toString()}` : ""
    }${status !== "" ? `&status=${status}` : ""}${
      vehicleRegNo !== "" ? `&vehicleRegNo=${vehicleRegNo}` : ""
    }`;

    axios
      .get(url)
      .then(res => {
        let response = res.data;
        console.log(res, "reshere");
        if (response.success === true) {
          this.setState({
            countOfPayments: response.data.countOfPayments,
            countOfPaymentsByLastWeek: response.data.countOfPaymentsByLastWeek,
            paymentsForChartList: response.data.paymentsForChartList,
            sumOfPayments: response.data.sumOfPayments,
            sumOfPaymentsByDriver: response.data.sumOfPaymentsByDriver,
            sumOfPaymentsByLastWeek: response.data.sumOfPaymentsByLastWeek,
            sumOfPaymentsByType: response.data.sumOfPaymentsByType
          });

          this.setState({
            sumOfPaymentsByDriverChartOptions: {
              options: {
                colors: [
                  "#FFE700",
                  "#f1b640",
                  "#f8de7e",
                  "#fada5e",
                  "#d2b55b",
                  "#fcf4a3",
                  "#fcd12a",
                  "#FFF8B3"
                ],
                legend: {
                  //   customLegendItems: ["Cash", "Checque", "Voucher"],
                  fontSize: "14px",
                  labels: {
                    colors: [
                      "#FFE700",
                      "#f1b640",
                      "#f8de7e",
                      "#fada5e",
                      "#d2b55b",
                      "#fcf4a3",
                      "#fcd12a",
                      "#FFF8B3"
                    ]
                  },
                  position: "bottom",
                  itemMargin: {
                    horizontal: 5,
                    vertical: 0
                  },
                  markers: {
                    offsetX: -2,
                    offsetY: 1
                  }
                },
                fill: {
                  colors: [
                    "#FFE700",
                    "#f1b640",
                    "#f8de7e",
                    "#fada5e",
                    "#d2b55b",
                    "#fcf4a3",
                    "#fcd12a",
                    "#FFF8B3"
                  ]
                },
                labels: response.data.sumOfPaymentsByDriver.map(
                  item => item.driverName
                ),
                dataLabels: {
                  enabled: false
                },
                plotOptions: {
                  pie: {
                    donut: {
                      size: "90%",
                      labels: {
                        show: true,

                        total: {
                          show: true,
                          label: "Total",
                          color: "#373d3f",
                          fontSize: "16px",
                          fontFamily: undefined,
                          formatter: function(w) {
                            return w.globals.seriesTotals.reduce((a, b) => {
                              return Math.round((a + b) * 100) / 100;
                            }, 0);
                          }
                        }
                      }
                    }
                  }
                },
                chart: {
                  type: "donut"
                },
                responsive: [
                  {
                    breakpoint: 480,
                    options: {
                      chart: {
                        width: 200
                      }
                    }
                  }
                ]
              }
            }
          });
          this.setState({
            sumOfPaymentsByTypeChartOptions: {
              options: {
                colors: [
                  "#FFE700",
                  "#f1b640",
                  "#f8de7e",
                  "#fada5e",
                  "#d2b55b",
                  "#fcf4a3",
                  "#fcd12a",
                  "#FFF8B3"
                ],
                legend: {
                  //   customLegendItems: ["Cash", "Checque", "Voucher"],
                  fontSize: "14px",
                  labels: {
                    colors: [
                      "#FFE700",
                      "#f1b640",
                      "#f8de7e",
                      "#fada5e",
                      "#d2b55b",
                      "#fcf4a3",
                      "#fcd12a",
                      "#FFF8B3"
                    ]
                  },
                  position: "bottom",
                  itemMargin: {
                    horizontal: 5,
                    vertical: 0
                  },
                  markers: {
                    offsetX: -2,
                    offsetY: 1
                  }
                },
                fill: {
                  colors: [
                    "#FFE700",
                    "#f1b640",
                    "#f8de7e",
                    "#fada5e",
                    "#d2b55b",
                    "#fcf4a3",
                    "#fcd12a",
                    "#FFF8B3"
                  ]
                },
                labels: response.data.sumOfPaymentsByType.map(
                  item => item.paymentType
                ),
                dataLabels: {
                  enabled: false
                },
                plotOptions: {
                  pie: {
                    donut: {
                      size: "90%",
                      labels: {
                        show: true,

                        total: {
                          show: true,
                          label: "Total",
                          color: "#373d3f",
                          fontSize: "16px",
                          fontFamily: undefined,
                          formatter: function(w) {
                            return w.globals.seriesTotals.reduce((a, b) => {
                              return Math.round((a + b) * 100) / 100;
                            }, 0);
                          }
                        }
                      }
                    }
                  }
                },
                chart: {
                  type: "donut"
                },
                responsive: [
                  {
                    breakpoint: 480,
                    options: {
                      chart: {
                        width: 200
                      }
                    }
                  }
                ]
              }
            }
          });

          this.setState({
            totalPaymentsChartOption: {
              series: [
                {
                  name: "Total Amount",
                  data: response.data.paymentsForChartList.map(
                    item => item.totalAmount
                  )
                },
                {
                  name: "Total Payment",
                  data: response.data.paymentsForChartList.map(
                    item => item.totalPayment
                  )
                }
              ],
              options: {
                colors: ["rgba(35,35,35, 0.9)", "#f1b640"],
                fill: {
                  colors: ["rgba(35,35,35, 0.9)", "#f1b640"],
                  opacity: 0.9,
                  type: "image"
                },
                chart: {
                  background: "#fff",
                  height: 350,
                  type: "area",
                  toolbar: {
                    show: false
                  },
                  zoom: {
                    enabled: false
                  }
                },
                dataLabels: {
                  enabled: false
                },
                stroke: {
                  curve: "smooth",
                  colors: ["rgba(35,35,35, 0.9)", "#f1b640"]
                },
                xaxis: {
                  type: "datetime",
                  categories: response.data.paymentsForChartList.map(
                    item => item.paymentAddedAt
                  )
                },
                // yaxis: {
                  
                //   categories: response.data.paymentsForChartList.map(
                //     item => item.totalAmount
                //   )
                // },
                tooltip: {
                  x: {
                    show: false,
                    format: "dd/MM/yy"
                  }
                }
              }
            }
          });

          this.setState({
            countOfPaymentsByLastWeekChartOption: {
              options: {
                fill: {
                  colors: ["rgba(35,35,35, 0.9)"]
                },
                chart: {
                  id: "basic-bar",
                  toolbar: {
                    show: false
                  },
                  zoom: {
                    enabled: false
                  },

                  colors: ["rgba(35,35,35, 0.9)", "rgba(35,35,35, 0.9)"],
                  foreColor: "rgba(35,35,35, 0.9)",
                  stroke: {
                    show: true,
                    width: 10,
                    colors: ["transparent"]
                  }
                },
                plotOptions: {
                  bar: {
                    horizontal: false,
                    borderRadius: 2
                  }
                },
                grid: {
                  show: false,
                  xaxis: {
                    lines: {
                      show: false
                    }
                  },
                  yaxis: {
                    lines: {
                      show: false
                    }
                  }
                },
                xaxis: {
                  labels: {
                    show: false
                  },
                  enabled: false,
                  categories: response.data.countOfPaymentsByLastWeek.map(
                    item => item.paymentAddedAt
                  ),
                  axisBorder: {
                    show: false
                  },
                  axisTicks: {
                    show: false
                  }
                },
                yaxis: {
                  labels: {
                    show: false
                  }
                },
                dataLabels: {
                  enabled: false
                }
              },
              series: [
                {
                  name: "Count of Payments in last week",
                  data: response.data.countOfPaymentsByLastWeek.map(item =>
                    parseInt(item.countOfPayments)
                  )
                }
              ]
            }
          });
          this.setState({
            sumOfPaymentsByLastWeekChartOption: {
              options: {
                fill: {
                  colors: ["#f1b640"]
                },
                chart: {
                  id: "basic-bar",
                  toolbar: {
                    show: false
                  },
                  zoom: {
                    enabled: false
                  },

                  colors: ["rgb(255, 231, 0)", "rgb(255, 231, 0)"],
                  foreColor: "rgb(255, 231, 0)",
                  stroke: {
                    show: true,
                    width: 10,
                    colors: ["transparent"]
                  }
                },
                plotOptions: {
                  bar: {
                    horizontal: false,
                    borderRadius: 2
                  }
                },
                grid: {
                  show: false,
                  xaxis: {
                    lines: {
                      show: false
                    }
                  },
                  yaxis: {
                    lines: {
                      show: false
                    }
                  }
                },
                xaxis: {
                  labels: {
                    show: false
                  },
                  enabled: false,
                  categories: response.data.sumOfPaymentsByLastWeek.map(
                    item => item.paymentAddedAt
                  ),
                  axisBorder: {
                    show: false
                  },
                  axisTicks: {
                    show: false
                  }
                },
                yaxis: {
                  labels: {
                    show: false
                  }
                },
                dataLabels: {
                  enabled: false
                }
              },
              series: [
                {
                  name: "Total Payments in last week",
                  data: response.data.sumOfPaymentsByLastWeek.map(
                    item => item.sumOfPayments
                  )
                }
              ]
            }
          });
        } else {
          this.setState({
            countOfPayments: "",
            countOfPaymentsByLastWeek: [],
            paymentsForChartList: [],
            sumOfPayments: "",
            sumOfPaymentsByDriver: [],
            sumOfPaymentsByLastWeek: [],
            sumOfPaymentsByType: []
          });
        }
        this.setState({ showCircularProgress2: false });
      })
      .catch(function(error) {
        if (error.response.status === 403) {
          logoutUser();
        }
      });
  };
  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleDriverChange = (event, value) => {
    console.log(value, "event");
    if (value !== null) {
      this.setState({ driverName: value.driverName });
    } else {
      this.setState({ driverName: "" });
    }
  };
  handlePaymentChange = (event, value) => {
    if (value !== null) {
      this.setState({ paymentType: value.paymentType });
    } else {
      this.setState({ paymentType: "" });
    }
  };

  handleProductChange = (event, value) => {
    console.log(value, "event");
    if (value !== null) {
      this.setState({ product: value });
    } else {
      this.setState({ product: "" });
    }
  };
  handleVehicleRegChange = (event, value) => {
    if (value !== null) {
      this.setState({ vehicleRegNo: value });
    } else {
      this.setState({ vehicleRegNo: "" });
    }
  };
  handleByDateChange = date => {
    console.log("pageChange", date);
    this.setState({ deliveryBy: date });
  };
  handleFromDateChange = date => {
    console.log("pageChange", date);
    this.setState({ deliveryFrom: date });
  };
  handlePageChange = (event, pageNumber) => {
    console.log("pageChange", pageNumber);
    this.allPayments(pageNumber);
    this.paymentSummary(pageNumber);
    this.setState({ filterPage: pageNumber });
  };
  searchJob = () => {
    this.paymentSummary(1);
    this.allPayments(1);
  };

  updatePayments = () => {
   
    this.allPayments(1);
  };
  handleSearch = event => {
    let data = this.state.paymentListData;
    const newData = data.filter(item => {
      if (!event.target.value) {
        return item;
      }
      if (item.invoiceNumber.toString().includes(event.target.value)) {
        return item;
      }
    });
    this.setState({ paymentListFilteredData: newData });
  };
  getAllDrivers = () => {
    const { logoutUser } = this.props;
    const url = `job/driver/all`;
    axios
      .get(url)
      .then(res => {
        let response = res.data;
        if (response.success === true) {
          this.setState({ allDrivers: response.data });
        } else {
        }
      })
      .catch(function(error) {
        if (error.response.status === 403) {
          logoutUser();
        }
      });
  };
  getAllProducts = () => {
    const { logoutUser } = this.props;
    const url = `job/product/all`;
    axios
      .get(url)
      .then(res => {
        let response = res.data;
        if (response.success === true) {
          this.setState({ allProducts: response.data });
        } else {
        }
      })
      .catch(function(error) {
        if (error.response.status === 403) {
          logoutUser();
        }
      });
  };
  getAllVehicles = () => {
    const { logoutUser } = this.props;
    const url = `job/vehicle/all`;
    axios
      .get(url)
      .then(res => {
        let response = res.data;
        if (response.success === true) {
          this.setState({ allVehicles: response.data });
        } else {
        }
      })
      .catch(function(error) {
        if (error.response.status === 403) {
          logoutUser();
        }
      });
  };
  jobDetailBack = () => {
    this.setState({ jobDetailView: false });
  };
  //   startAutoJob=()=>{
  //     this.setState({ currentRunningJob: true });
  //     let url = "/start/automated/job";
  //     axios.post(url).then(res => {
  //       let response = res.data;
  //       if (response.success === true) {
  //         this.setState({ currentRunningJob: true });
  //         this.showSnackBar(response.message);
  //       } else {
  //         this.setState({ currentRunningJob: false });
  //         this.showSnackBar(response.message);
  //         // this.setState({jobStages:})
  //       }
  //     });
  //   }

  handleJobDetailView = row => {
    this.setState({ jobDetailView: true, selectedJob: row });
  };

  showSnackBar = message => {
    this.setState({ openSnackbar: true, snackBarMessage: message });
  };
  handleSnackBarClose = () => {
    this.setState({ openSnackbar: false });
  };

  render() {
    const { classes } = this.props;
    const {
      openSnackbar,
      snackBarMessage,

      totalPages,
      tableColumns,
      jobListData,
      litres,
      postCode,
      litre,
      productType,
      onlinePrice,
      targetPrice,
      errorPostCode,
      errorLitre,
      deliveryFrom,
      deliveryBy,
      showCircularProgress1,
      showCircularProgress2,
      jobDetailView,
      selectedJob,
      paymentListFilteredData,
      allDrivers,
      allProducts,
      allVehicles,
      countOfPayments,
      countOfPaymentsByLastWeek,
      paymentsForChartList,
      sumOfPayments,
      sumOfPaymentsByDriver,
      sumOfPaymentsByLastWeek,
      sumOfPaymentsByType,
      sumOfPaymentsByDriverChartOptions,
      sumOfPaymentsByTypeChartOptions,
      totalPaymentsChartOption,
      countOfPaymentsByLastWeekChartOption,
      sumOfPaymentsByLastWeekChartOption
    } = this.state;
    return (
      <>
        {!jobDetailView && (
          <>
            <Grid
              className={classes.grid}
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid
                className={classes.grid}
                container
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                {/* <div className={classes.subHeading}>Job Filters</div> */}
                <Grid item xs={12} md={12}>
                  {/* <TextField
                    value={this.state.orderNumber}
                    name="orderNumber"
                    label="Order Number"
                    className={classes.textField}
                    margin="normal"
                    onChange={this.handleChange}
                    variant="outlined"
                    // error={errorFirstName}
                  /> */}

                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      label="Date From"
                      value={deliveryBy}
                      onChange={this.handleByDateChange}
                      name="deliverBy"
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                      className={classes.textField}
                    />
                  </MuiPickersUtilsProvider>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      label="Date To"
                      name="deliveryFrom"
                      value={deliveryFrom}
                      onChange={this.handleFromDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                      className={classes.textField}
                    />
                  </MuiPickersUtilsProvider>

                  <Autocomplete
                    className={classes.autoComplete}
                    name="driverName"
                    options={allDrivers}
                    getOptionLabel={option => option.driverName}
                    onChange={(event, value) =>
                      this.handleDriverChange(event, value)
                    }
                    renderInput={params => (
                      <TextField
                        value={this.state.driverName}
                        className={classes.textField}
                        {...params}
                        label="Driver Name"
                        variant="outlined"
                      />
                    )}
                  />
                

                  {/* <Autocomplete
                    className={classes.autoComplete}
                    name="vehicleRegNo"
                    options={allVehicles}
                    getOptionLabel={option => option}
                    onChange={(event, value) =>
                      this.handleVehicleRegChange(event, value)
                    }
                    renderInput={params => (
                      <TextField
                        value={this.state.vehicleRegNo}
                        className={classes.textField}
                        {...params}
                        label="Vehicle Reg No"
                        variant="outlined"
                      />
                    )}
                  /> */}
                  {/* <TextField
                    value={this.state.invoiceNumber}
                    name="invoiceNumber"
                    label="Invoice Number"
                    className={classes.textField}
                    margin="normal"
                    onChange={this.handleChange}
                    variant="outlined"
                    // error={errorFirstName}
                  /> */}
                  <>
                    {/* <Autocomplete
                      className={classes.autoComplete}
                      name="product"
                      options={allProducts}
                      getOptionLabel={option => option}
                      onChange={(event, value) =>
                        this.handleProductChange(event, value)
                      }
                      renderInput={params => (
                        <TextField
                          value={this.state.product}
                          className={classes.textField}
                          {...params}
                          label="Product"
                          variant="outlined"
                        />
                      )}
                    /> */}
                  </>

                  {/* <TextField
                    value={this.state.quantity}
                    name="quantity"
                    label="Quantity"
                    className={classes.textField}
                    margin="normal"
                    onChange={this.handleChange}
                    variant="outlined"
                    // error={errorFirstName}
                  /> */}
                  <FormControl
                    variant="outlined"
                    className={classes.selectFeild}
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Payment Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={this.state.paymentType}
                      name="paymentType"
                      onChange={this.handleChange}
                      label="Payment Type"
                    >
                      <MenuItem value="">
                        <em>All</em>
                      </MenuItem>
                      <MenuItem value={"Cash"}>Cash</MenuItem>
                      <MenuItem value={"Cheque"}>Cheque</MenuItem>
                      <MenuItem value={"Voucher"}>Voucher</MenuItem>
                      <MenuItem value={"Card"}>Card</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    value={this.state.customerName}
                    name="customerName"
                    label="Customer Name"
                    className={classes.textField}
                    margin="normal"
                    onChange={this.handleChange}
                    variant="outlined"
                    // error={errorFirstName}
                  />
  <FormControl
                    variant="outlined"
                    className={classes.autoComplete}
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                    Payment Confirmed
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={this.state.paymentConfirmed}
                      name="paymentConfirmed"
                      onChange={this.handleChange}
                      label="paymentConfirmed"
                    >
                        <MenuItem value={""}>All</MenuItem>
                      <MenuItem value={true}>Confirmed</MenuItem>
                      <MenuItem value={false}>Not Confirmed</MenuItem>
                    </Select>
                  </FormControl>
                  {/* <FormControl
                    variant="outlined"
                    className={classes.selectFeild}
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Page Size
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={this.state.pageSize}
                      name="pageSize"
                      onChange={this.handleChange}
                      label="Page Size"
                    >
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={15}>15</MenuItem>
                      <MenuItem value={20}>20</MenuItem>
                      <MenuItem value={30}>30</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                    </Select>
                  </FormControl> */}

                  {/* <TextField
                        value={this.state.vehicleRegNo}
                        name="vehicleRegNo"
                        label="Vehicle Reg No"
                        className={classes.textField}
                        margin="normal"
                        onChange={this.handleChange}
                        variant="outlined"
                        // error={errorFirstName}
                      /> */}

                  <Button
                    variant="contained"
                    className={classes.startAutoJob}
                    disabled={showCircularProgress1 || showCircularProgress2}
                    onClick={() => this.searchJob()}
                  >
                    {(showCircularProgress1 || showCircularProgress2) && (
                      <CircularProgress
                        size={20}
                        className={classes.circularProgress}
                      />
                    )}
                    {!(showCircularProgress1 || showCircularProgress2) &&
                      "Search"}
                  </Button>
                </Grid>
              </Grid>
              {/* </AccordionDetails>
              </Accordion> */}
            </Grid>

            <Grid
              className={classes.grid}
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              {countOfPayments > 0 && (
                <PaymentSubs
                  countOfPayments={countOfPayments}
                  countOfPaymentsByLastWeek={countOfPaymentsByLastWeek}
                  paymentsForChartList={paymentsForChartList}
                  sumOfPayments={sumOfPayments}
                  sumOfPaymentsByDriver={sumOfPaymentsByDriver}
                  sumOfPaymentsByLastWeek={sumOfPaymentsByLastWeek}
                  sumOfPaymentsByType={sumOfPaymentsByType}
                  countOfPaymentsByLastWeekChartOption={
                    countOfPaymentsByLastWeekChartOption
                  }
                  sumOfPaymentsByLastWeekChartOption={
                    sumOfPaymentsByLastWeekChartOption
                  }
                />
              )}
            </Grid>
            <Grid
              className={classes.grid}
              direction="row"
              justify="space-between"
              alignItems="center"
           
            >
              {countOfPayments > 0 && (
                <PaymentChart
                  countOfPayments={countOfPayments}
                  countOfPaymentsByLastWeek={countOfPaymentsByLastWeek}
                  paymentsForChartList={paymentsForChartList}
                  sumOfPayments={sumOfPayments}
                  sumOfPaymentsByDriver={sumOfPaymentsByDriver}
                  sumOfPaymentsByLastWeek={sumOfPaymentsByLastWeek}
                  sumOfPaymentsByType={sumOfPaymentsByType}
                  sumOfPaymentsByDriverChartOptions={
                    sumOfPaymentsByDriverChartOptions
                  }
                  sumOfPaymentsByTypeChartOptions={
                    sumOfPaymentsByTypeChartOptions
                  }
                  totalPaymentsChartOption={totalPaymentsChartOption}
                />
              )}
            </Grid>
           
            <Grid
              className={classes.grid}
              direction="row"
              justify="space-between"
              alignItems="center"
           
            >
            <PaymentTable
              rows={paymentListFilteredData}
              tableColumns={tableColumns}
              classes={classes}
              tableName={"Paymment List"}
              markupType="kero"
             
              showSnackBar={this.showSnackBar}
              
              handlePageChange={this.handlePageChange}
              totalPages={totalPages}
              updatePayments={this.updatePayments}
            />
            </Grid>
          </>
        )}
        {/* {jobDetailView && (
          <JobDetail
            selectedJob={selectedJob}
            allDrivers={allDrivers}
            jobDetailBack={this.jobDetailBack}
            showSnackBar={this.showSnackBar}
          />
        )} */}
        <ConfirmedSnackBar
          open={openSnackbar}
          message={snackBarMessage}
          handleClose={this.handleSnackBarClose}
          classes={classes}
        />
      </>
    );
  }
}

export default withStyles(styles)(Payments);
