import React, { useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Pagination from "@material-ui/lab/Pagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import LaunchIcon from "@material-ui/icons/Launch";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import TableHead from "@material-ui/core/TableHead";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Input from "@material-ui/core/Input";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Chip from "@material-ui/core/Chip";
import SaveIcon from "@material-ui/icons/Save";
import { axios } from "../../utils/axiosConfig";
import { UserContextData, UserContext } from "../../Contexts/userContext";
import moment from "moment-timezone";
export default function JobListTable(props) {
  const classes = props.classes;

  //   const { table } = props.table;
  const [page, setPage] = React.useState(0);
  const { user, logoutUser } = useContext(UserContext);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [isCellHovered, setCellHover] = React.useState(null);
  const [cellValue, setCellValue] = React.useState(null);
  const [moneySwitchValue, setMoneySwitchValue] = React.useState(null);
  const [colossusSwitchValue, setColossusSwitchValue] = React.useState(null);
  const [updatedData, setDataUpdate] = React.useState([]);
  const [updateTable, setUpdateTable] = React.useState(false);
  const [saveProgress, setSaveProgress] = React.useState(false);
  const [rowData, setRowData] = React.useState(props.rows);
  const [dataForFilter, setDataForFilter] = React.useState(props.rows);
  const [inputFocus, setInputFocus] = React.useState(true);
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, props.rows.length - page * rowsPerPage);

  const cellHover = cellValue => {
    setCellHover(cellValue);
  };
  const cellLeave = cellValue => {
    setCellHover(null);
  };

  useEffect(() => {
    setRowData(props.rows);
    setMoneySwitchValue(null);
    setColossusSwitchValue(null);
    setUpdateTable(false);
  }, [props.rows]);

  const handleSwitchChange = (event, jobID, feild, row) => {
    const index2 = rowData.findIndex(e => e.jobID === jobID);
    const newRows = [...rowData];
    let newRowValue = { ...newRows[index2] };
    newRowValue.logBookProgress = true;
    newRows[index2] = newRowValue;
    setRowData(newRows);
    let newElement = {};

    newElement = {
      jobID: jobID,
      logBookConfirmed: event.target.checked ? 1 : 0,
      logBookConfirmedAt: moment()
        .tz("Europe/London")
        .format("YYYY-MM-DD hh:mm:ss"),
      logBookConfirmedBy: user.firstName + " " + user.lastName
    };

    setSaveProgress(true);
    let url = "job/status";
    let body = {
      jobs: [newElement]
    };

    axios.post(url, body).then(res => {
      setSaveProgress(false);
      let response = res.data;
      if (response.success === true) {
        props.showSnackBar("Data Saved");
        props.updateJob();
      } else {
        props.showSnackBar("Unable to Save Data");
        props.updateJob();
      }
    });

    // //money  recieved
    // if (!updateTable) {
    //   setUpdateTable(true);
    // }

    // let newElement = {};
    // newElement = {
    //   jobID: jobID,
    //   moneyReceived: event.target.checked,
    //   colossusConfirmed: row.colossusConfirmed
    // };

    // const index = updatedData.findIndex(e => e.jobID === jobID);
    // if (index == -1) {
    //   setDataUpdate([...updatedData, newElement]);
    // } else {
    //   const newData = [...updatedData];
    //   let newDataValue = { ...newData[index] };
    //   newDataValue.moneyReceived = event.target.checked;
    //   newData[index] = newDataValue;
    //   setDataUpdate(newData);
    // }

    // const index2 = rowData.findIndex(e => e.jobID === jobID);
    // const newRows = [...rowData];
    // let newRowValue = { ...newRows[index2] };
    // newRowValue[feild] = event.target.checked;
    // newRows[index2] = newRowValue;
    // setRowData(newRows);

    // const index3 = dataForFilter.findIndex(e => e.jobID === jobID);
    // const newRowsFilter = [...dataForFilter];
    // let newRowValueFilter = { ...newRowsFilter[index3] };
    // newRowValueFilter[feild] = event.target.checked;
    // newRowsFilter[index3] = newRowValueFilter;
    // setDataForFilter(newRowsFilter);
  };

  const handleCurrentValueSwitchChange = (event, jobID, feild, row) => {
    const index2 = rowData.findIndex(e => e.jobID === jobID);
    const newRows = [...rowData];
    let newRowValue = { ...newRows[index2] };
    newRowValue.colossusProgress = true;
    newRows[index2] = newRowValue;
    setRowData(newRows);
    let newElement = {};

    newElement = {
      jobID: jobID,
      colossusConfirmed: event.target.checked ? 1 : 0,
      colossusConfirmedAt: moment()
        .tz("Europe/London")
        .format("YYYY-MM-DD hh:mm:ss"),
      colossusConfirmedBy: user.firstName + " " + user.lastName
    };

    setSaveProgress(true);
    let url = "job/status";
    let body = {
      jobs: [newElement]
    };

    axios.post(url, body).then(res => {
      setSaveProgress(false);
      let response = res.data;
      if (response.success === true) {
        props.showSnackBar("Data Saved");
        props.updateJob();
      } else {
        props.showSnackBar("Unable to Save Data");
        props.updateJob();
      }
    });

    // const index = updatedData.findIndex(e => e.jobID === jobID);
    // if (index == -1) {
    //   setDataUpdate([...updatedData, newElement]);
    // } else {
    //   const newData = [...updatedData];
    //   let newDataValue = { ...newData[index] };
    //   newDataValue.colossusConfirmed = event.target.checked;
    //   newData[index] = newDataValue;
    //   setDataUpdate(newData);
    // }
    // const index2 = rowData.findIndex(e => e.jobID === jobID);
    // console.log(rowData, feild, "indexFound");
    // const newRows = [...rowData];
    // let newRowValue = { ...newRows[index2] };
    // newRowValue[feild] = event.target.checked;
    // newRows[index2] = newRowValue;
    // setRowData(newRows);

    // const newRowsFilter = [...dataForFilter];
    // let newRowValueFilter = { ...newRowsFilter[index2] };
    // newRowValueFilter[feild] = event.target.checked;
    // newRowsFilter[index2] = newRowValueFilter;
    // setDataForFilter(newRowsFilter);
  };

  const handleSearch = event => {
    // console.log(inputRef,event,"inputRef")
    // inputRef.current.blur();
    setInputFocus(false);
    let rows = dataForFilter;
    const newData = rows.filter(item => {
      if (!event.target.value) {
        return item;
      }
      if (
        item.orderNumber
          .toLowerCase()
          .includes(event.target.value.toLowerCase())
      ) {
        return item;
      }
    });

    setRowData(newData);
  };

  const saveData = () => {
    const updatedDataColossus = updatedData.map(data =>
      data.colossusConfirmed === true
        ? { ...data, colossusConfirmed: 1 }
        : { ...data, colossusConfirmed: 0 }
    );

    const updatedDataMoney = updatedDataColossus.map(data =>
      data.moneyReceived === true
        ? { ...data, moneyReceived: 1 }
        : { ...data, moneyReceived: 0 }
    );

    console.log(updatedDataMoney, "updatedDataupdatedDataupdatedData");

    setSaveProgress(true);
    let url = "job/status";
    let body = {
      jobs: updatedDataMoney
    };

    axios.post(url, body).then(res => {
      setSaveProgress(false);
      let response = res.data;
      if (response.success === true) {
        props.showSnackBar("Data Saved");
        props.updateJob();
      } else {
        props.showSnackBar("Unable to Save Data");
        props.updateJob();
      }
    });
  };

  return (
    <>
      <div className={classes.tableHeading}>
        <Typography>{props.tableName}</Typography>
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            onChange={e => handleSearch(e)}
            placeholder="Search Order Number… "
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput
            }}
            inputProps={{ "aria-label": "search" }}
          />
        </div>
      </div>

      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table
          aria-label="custom pagination table"
          className={classes.table}
          // padding="none"
          style={{ width: "auto", tableLayout: "auto" }}
        >
          <TableHead>
            <TableRow className={classes.tableHead}>
              {props.tableColumns.map(column => (
                <TableCell variant="head" align="center" padding="none" className={classes.tableHeadCol}>
                  <div >{column.title}</div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableHead}>
            {rowData.map(row => (
              <TableRow key={row.name} className={classes.tableRowMain}>
                {props.tableColumns.map(column => (
                  <TableCell
                  className={classes.tableHeadCol}
                    // size="small"
                    padding="checkbox"
                    onMouseOver={() =>
                      cellHover(row.jobID + "-" + column.field)
                    }
                    onMouseLeave={() =>
                      cellLeave(row.jobID + "-" + column.field)
                    }
                    component="th"
                    scope="row"
                    align="center"
                  >
                    {cellValue != row.jobID + "-" + column.field && (
                      <div className={classes.tableCellClass}>
                        {column.field !== "logBookConfirmed" &&
                          column.field !== "colossusConfirmed" &&
                          (column.field === "assignedDate" ? (
                            moment(row[column.field])
                              .tz("Europe/London")
                              .format("DD/MM/YYYY hh:mm A")
                          ) : column.field === "action" ? (
                            <div className={classes.tableBold}>
                              <LaunchIcon
                                className={classes.launchIcon}
                                onClick={e => props.handleJobDetailView(row)}
                              />
                            </div>
                          ) : column.field === "status" ? (
                            <Chip
                              size="small"
                              style={
                                row[column.field] === "Completed"
                                  ? {
                                      backgroundColor: "#e5f9ed",
                                      color: "#1bc943",
                                      fontWeight: 600,
                                      padding: "5px"
                                    }
                                  : row[column.field] === "Suspended" ||
                                    row[column.field] === "Cancelled"
                                  ? {
                                      backgroundColor: "#fff5f6",
                                      color: "#f83245",
                                      fontWeight: 600,
                                      padding: "5px"
                                    }
                                  : {
                                      backgroundColor: "#fff8e1",
                                      color: "#ffc107",
                                      fontWeight: 600,
                                      padding: "5px"
                                    }
                              }
                              label={row[column.field]}
                              className={
                                row[column.field] === true
                                  ? classes.enabledChip
                                  : classes.disabledChip
                              }
                            />
                          ) : column.field === "product" ? (
                            <Chip
                              size="small"
                              style={
                                row[column.field] === "Kerosene 28 Secs"
                                  ? {
                                      backgroundColor: "#3d6fb5",
                                      color: "#ffffff",
                                      fontWeight: 600,
                                      padding: "5px"
                                    }
                                  : row[column.field] ===
                                      "Kerosene +FBoost13" ||
                                    row[column.field] === "Kerosene +FBoost5" ||
                                    row[column.field] ===
                                      "Kerosene +FBoost20" ||
                                    row[column.field] ===
                                      "Kerosene +FBoost10" ||
                                    row[column.field] === "Kerosene +FBoost3"
                                  ? {
                                      backgroundColor: "#64ade5",
                                      color: "#ffffff",
                                      fontWeight: 600,
                                      padding: "5px"
                                    }
                                  : row[column.field] ===
                                      "Essar Marked Gasoil (undosed)" ||
                                    row[column.field] === "Gas Oil 35 Secs"
                                  ? {
                                      backgroundColor: "#d94332",
                                      color: "#fff5f6",
                                      fontWeight: 600,
                                      padding: "5px"
                                    }
                                  : row[column.field] === "Gas Oil +FBoost3" ||
                                    row[column.field] === "Gas Oil +FBoost5" ||
                                    row[column.field] === "Gas Oil +FBoost10" ||
                                    row[column.field] === "Gas Oil +FBoost13" ||
                                    row[column.field] === "Gas Oil +FBoost20" ||
                                    row[column.field] === "Gas Oil +FBoost23"
                                  ? {
                                      backgroundColor: "#e08886",
                                      color: "#ffffff",
                                      fontWeight: 600,
                                      padding: "5px"
                                    }
                                  : row[column.field] === "U.L.S.DERV" ||
                                    row[column.field] ===
                                      "Essar Diesel (undosed)" ||
                                    row[column.field] ===
                                      "Essar Premium Diesel (dosed)"
                                  ? {
                                      backgroundColor: "#000000",
                                      color: "#ffffff",
                                      fontWeight: 600,
                                      padding: "5px"
                                    }
                                  : row[column.field] ===
                                      "Essar Premium Unleaded 95 (dosed)" ||
                                    row[column.field] ===
                                      "Essar Unleaded 95 (undosed)"
                                  ? {
                                      backgroundColor: "#a9ca71",
                                      color: "#ffffff",
                                      fontWeight: 600,
                                      padding: "5px"
                                    }
                                  : {
                                      backgroundColor: "#ffc107",
                                      color: "#fff8e1",
                                      fontWeight: 600,
                                      padding: "5px"
                                    }
                              }
                              label={row[column.field]}
                              className={
                                row[column.field] === true
                                  ? classes.enabledChip
                                  : classes.disabledChip
                              }
                            />
                          ) : row[column.field] ? (
                            column.field === "orderNumber" ||
                            column.field === "customerName" ||
                            column.field === "tripNo" ||
                            column.field === "runSequence" ? (
                              <div className={classes.tableBold}>
                                {row[column.field]}
                              </div>
                            ) : (
                              row[column.field]
                            )
                          ) : row[column.field] === 0 ? (
                            "0"
                          ) : (
                            "N/A"
                          ))}
                        {(column.field === "logBookConfirmed" ||
                          column.field === "colossusConfirmed") &&
                          colossusSwitchValue !=
                            row.jobID + "-" + column.field &&
                          moneySwitchValue !=
                            row.jobID + "-" + column.field && (
                            <Chip
                              size="small"
                              style={
                                row[column.field] === true
                                  ? {
                                      backgroundColor: "#e5f9ed",
                                      color: "#1bc943"
                                    }
                                  : {
                                      backgroundColor: "#fff5f6",
                                      color: "#f83245"
                                    }
                              }
                              label={
                                row[column.field] === true
                                  ? column.field === "logBookConfirmed"
                                    ? "Confirmed"
                                    : "Confirmed "
                                  : column.field === "colossusConfirmed"
                                  ? "Not Confirmed"
                                  : " Not Confirmed"
                              }
                              className={
                                row[column.field] === true
                                  ? classes.enabledChip
                                  : classes.disabledChip
                              }
                            />
                          )}
                        {column.field !== "currentlyPricing" &&
                          column.field === "isEnabled" &&
                          moneySwitchValue !=
                            row.jobID + "-" + column.field && (
                            <Chip
                              size="small"
                              style={
                                row[column.field] === true
                                  ? {
                                      backgroundColor: "#e5f9ed",
                                      color: "#1bc943"
                                    }
                                  : {
                                      backgroundColor: "#fff5f6",
                                      color: "#f83245"
                                    }
                              }
                              label={
                                row[column.field] === true
                                  ? "Enabled"
                                  : "Disabled"
                              }
                              className={
                                row[column.field] === true
                                  ? classes.enabledChip
                                  : classes.disabledChip
                              }
                            />
                          )}

                        {isCellHovered == row.jobID + "-" + column.field &&
                          // row.isEnabled == true ||
                          (column.field == "logBookConfirmed" ||
                            column.field == "colossusConfirmed") &&
                          moneySwitchValue != row.jobID + "-" + column.field &&
                          colossusSwitchValue !=
                            row.jobID + "-" + column.field &&
                          row.jobType.toLowerCase() !== "summary" && (
                            //
                            <EditIcon
                              className={classes.editIconChip}
                              onClick={() => {
                                column.field === "colossusConfirmed"
                                  ? setColossusSwitchValue(
                                      row.jobID + "-" + column.field
                                    )
                                  : column.field === "logBookConfirmed"
                                  ? setMoneySwitchValue(
                                      row.jobID + "-" + column.field
                                    )
                                  : setCellValue(
                                      row.postCode + "-" + column.field
                                    );

                                setInputFocus(true);
                              }}
                            />
                          )}
                      </div>
                    )}

                    {moneySwitchValue == row.jobID + "-" + column.field &&
                      !row.logBookProgress && (
                        <div className={classes.tableCellClass}>
                          <form noValidate autoComplete="off">
                            <Grid
                              component="label"
                              container
                              alignItems="center"
                              spacing={1}
                              className={classes.switchGrid}
                            >
                              <Grid item>Not Confirmed</Grid>
                              <Grid item>
                                <AntSwitch
                                  checked={row[column.field]}
                                  onChange={event =>
                                    handleSwitchChange(
                                      event,
                                      row.jobID,
                                      column.field,
                                      row
                                    )
                                  }
                                  name="checkedC"
                                />
                              </Grid>
                              <Grid item>Confirmed</Grid>
                            </Grid>
                          </form>
                        </div>
                      )}
                    {colossusSwitchValue == row.jobID + "-" + column.field &&
                      !row.colossusProgress && (
                        <div className={classes.tableCellClass}>
                          <form noValidate autoComplete="off">
                            <Grid
                              component="label"
                              container
                              alignItems="center"
                              spacing={1}
                              className={classes.switchGrid}
                            >
                              <Grid item>Not Confirmed</Grid>
                              <Grid item>
                                <AntSwitch
                                  checked={row[column.field]}
                                  onChange={event =>
                                    handleCurrentValueSwitchChange(
                                      event,
                                      row.jobID,
                                      column.field,
                                      row
                                    )
                                  }
                                  name="checkedC"
                                />
                              </Grid>
                              <Grid item>Confirmed</Grid>
                            </Grid>
                          </form>
                        </div>
                      )}
                    {colossusSwitchValue == row.jobID + "-" + column.field &&
                      row.colossusProgress && (
                        <div className={classes.tableCellClass}>
                          <CircularProgress
                            size={20}
                            className={classes.circularProgress}
                          />
                        </div>
                      )}
                    {moneySwitchValue == row.jobID + "-" + column.field &&
                      row.logBookProgress && (
                        <div className={classes.tableCellClass}>
                          <CircularProgress
                            size={20}
                            className={classes.circularProgress}
                          />
                        </div>
                      )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
            {rowData.length === 0 && (
              <Typography
                variant="h6"
                component="h6"
                className={classes.noDataFoundText}
              >
                No Data Found
              </Typography>
            )}

            {/* {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
               */}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={classes.displayFlex}>
        {updateTable && (
          <Button
            variant="contained"
            className={classes.saveData}
            // disabled={saveProgress}
            startIcon={!saveProgress && <SaveIcon />}
            onClick={() => saveData()}
          >
            {!saveProgress && "Save"}
            {saveProgress && (
              <CircularProgress
                size={20}
                className={classes.circularProgress}
              />
            )}
          </Button>
        )}
        <div className={classes.tableFooter}>
          <Pagination
            count={props.totalPages}
            variant="outlined"
            onChange={props.handlePageChange}
          />
        </div>
      </div>
    </>
  );
}

const AntSwitch = withStyles(theme => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: "flex"
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main
      }
    }
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none"
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white
  },
  checked: {}
}))(Switch);
