import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { UserContextProvider } from "./Contexts/userContext";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./utils/muiTheme";
import { BrowserRouter as Router } from "react-router-dom";

ReactDOM.render(
 
    <ThemeProvider theme={theme}>
      <UserContextProvider>
        <Router>
          <App />
        </Router>
      </UserContextProvider>
    </ThemeProvider>
  ,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
