import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import WorkOutlineIcon from "@material-ui/icons/WorkOutline";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import Grid from "@material-ui/core/Grid";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import FormControl from "@material-ui/core/FormControl";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import BusinessIcon from "@material-ui/icons/Business";
import CheckIcon from "@material-ui/icons/Check";
import CardContent from "@material-ui/core/CardContent";
import EditIcon from "@material-ui/icons/Edit";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import SearchIcon from "@material-ui/icons/Search";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import InputLabel from "@material-ui/core/InputLabel";
import LaunchIcon from "@material-ui/icons/Launch";
import LastPageIcon from "@material-ui/icons/LastPage";
import InputBase from "@material-ui/core/InputBase";
import { fade } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { axios } from "../../utils/axiosConfig";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ConfirmedSnackBar from "../../utils/ConfirmedSnackbar";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import JobDetail from "./JobDetail";
import TablePagination from "@material-ui/core/TablePagination";
import DateFnsUtils from "@date-io/date-fns";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import LocationSearchingIcon from "@material-ui/icons/LocationSearching";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import InfoIcon from "@material-ui/icons/Info";
import TimerIcon from "@material-ui/icons/Timer";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    maxHeight: 450
  },
  listSection: {
    backgroundColor: "inherit"
  },
  ul: {
    backgroundColor: "inherit",
    padding: 0
  },
  textField: {
    // width: "100%",
    marginLeft: "5px",
    marginRight: "5px"
  },
  selectFeild: {
    width: "15%",
    marginTop: "15px",
    marginLeft: "5px",
    marginRight: "5px"
  },

  tableHeadFull: {
    width: "100%"
  },
  datePicker: {
    selectColor: "black"
  },
  priceTextFeild: {
    margin: "26px"
  },
  jobCardPrice: {
    color: "#fff",
    fontSize: "52px",
    marginTop: "7px",
    marginBottom: "7px"
  },
  formControl: {
    width: "65%"
  },
  center: {
    margin: "auto",
    width: "fit-content"
  },
  search: {
    position: "relative",
    borderRadius: "4px",
    backgroundColor: fade("#fff", 0.15),
    "&:hover": {
      backgroundColor: fade("#fff", 0.25)
    },
    // marginLeft: 0,
    width: "250px",
    marginLeft: "auto"
  },
  searchIcon: {
    padding: "16px",
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "inherit"
  },
  inputInput: {
    padding: "8px, 8px, 8px, 8px",
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + 32px)`,
    width: "100%"
  },
  card: {
    width: "100%",
    backgroud: "rgba(35,35,35, 0.9); !important"
  },
  jobCardTitle: {
    marginTop: "10px",
    color: "#fff"
  },
  titleDiv: {
    display: "flex"
  },
  switchGrid: {
    margin: "auto",
    display: "flex"
  },
  switchClass: {
    margin: "3px 10px 5px 10px"
  },
  timeContainer: {
    margin: "43px 0px 0px 0px"
  },
  rootCard: {
    background: "rgba(35,35,35, 0.9)",
    width: "100%",
    marginRight: "80px"
  },
  showCircularProgressStyle: {
    margin: "auto",
    width: "fit-content",
    padding: "50%"
  },

  tableHeading: {
    padding: "15px",
    fontSize: "20px",
    color: "#fff",
    display: "flex",
    background: "rgba(35,35,35, 0.9);"
  },
  tableBold: {
    fontWeight: 600,
    fontSize: "17px",
    color: "rgba(35,35,35, 0.9);"
  },

  gridHere: {
    // borderRight:"1px solid rgba(35,35,35, 0.9);"
  },

  editIcon: {
    color: "#fff",
    fontSize: "16px",
    cursor: "pointer"
    // marginLeft: "20px",
    // marginBottom: "15px"
  },

  expandIcon: {
    color: "#fff",
    fontSize: "20px",
    cursor: "pointer"
    // marginLeft: "20px",
    // marginBottom: "15px"
  },
  launchIcon: {
    color: "rgba(35,35,35, 0.9);",
    cursor: "pointer"
  },
  oilIcons: {
    fontSize: "40px",
    color: "#fff",
    margin: "4px 4px"
  },
  priceIcons: {
    fontSize: "50px",
    color: "#fff",
    margin: "4px 4px"
  },
  editIconChip: {
    fontSize: "16px",
    cursor: "pointer"
  },
  tableCellClass: {
    display: "inline-flex"
  },
  inputFeildHovered: {
    color: "#fff",
    fontSize: "16px",
    width: "380px",
    background: "none",
    textAlign: "center",
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none"
    }
  },
  inputFeild: {
    color: "rgba(35,35,35, 0.9);",
    fontSize: "16px",
    width: "380px",
    background: "none",
    textAlign: "center",
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none"
    }
  },
  inputFeildHaulage: {
    color: "rgba(35,35,35, 0.9);",
    fontSize: "20px",
    background: "none",
    width: "40px",
    textAlign: "center",
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none"
    }
  },
  inputFeildHaulageHovered: {
    color: "#fff",
    fontSize: "20px",
    background: "none",
    width: "40px",
    textAlign: "center",
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none"
    }
  },

  startAutoJob: {
    color: "#fff",
    background: "rgba(35,35,35, 0.9);",
    width: "auto",
    height: "53px",
    marginTop: "17px",
    marginLeft: "10px",
    fontSize: "16px",
    "&:hover": {
      backgroundColor: "rgba(35,35,35, 0.9);",
      color: "#fff"
    }
  },
  startCron: {
    marginLeft: "auto",
    marginTop: "auto",
    marginBottom: "auto"
  },
  tablePagination: {
    marginLeft: "auto"
  },
  enabledChip: {
    backgroudColor: "green"
  },
  disabledChip: {
    backgroud: "red"
  },
  "& .MuiTablePagination-toolbar": {
    marginLeft: "auto"
  },
  tableFooter: {
    width: "fit-content",
    marginTop: "20px",
    marginLeft: "auto"
  },
  circularProgress: {
    marginLeft: "10px"
  },
  circularProgressCenter: {
    margin: "auto"
  },
  autoCircularProgress: {
    margin: "auto"
  },
  circularStyle: {
    width: "100%",
    margin: "auto",
    marginTop: "15%",
    marginBottom: "15%"
  },
  circularProgressBase: {
    width: "100%"
  },
  snackbar: {
    backgroundColor: "rgb(50, 50, 50)"
  },
  fullWidth: {
    width: "100%"
  },
  close: {
    color: "#fff"
  },
  menuButtonsIconPrimary: {
    marginLeft: "auto",
    color: "#fff",
    cursor: "pointer"
    // height: "50px"
  },
  dialogContent: {
    margin: "10px"
  },
  newCronTimePicker: {
    marginTop: "15px"
  },
  headerStyle: {
    display: "flex",
    width: "100%"
  },
  borderBottom: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)"
  },
  grid: {
    border: "1px solid rgba(0, 0, 0, 0.1)"
  },
  texts: {
    marginLeft: "15px"
  }
}));

export default function JobDetailList(props) {
  const classes = useStyles();
  const [job, setJob] = React.useState(false);

  useEffect(() => {
    setJob(props.jobData);
  }, [props.jobData]);

  const findIcon = name => {
    switch (name) {
      case "completedAt":
        return <CheckIcon />;
      case "createdAt":
        return <EditIcon />;
      case "customerAccountRef":
        return <InfoOutlinedIcon />;
      case "customerAddress":
        return <BusinessIcon />;
      case "customerEmail":
        return <EmailIcon />;
      case "customerHomeTelephoneNumber":
        return <PhoneIcon />;
      case "customerID":
        return <InfoOutlinedIcon />;
      case "customerLatitude":
        return <LocationSearchingIcon />;
      case "customerLongitude":
        return <LocationSearchingIcon />;
      case "customerMobile":
        return <PhoneIcon />;
      case "customerName":
        return <AccountCircleIcon />;
      case "customerOrderNumber":
        return <InfoOutlinedIcon />;
      case "deliveryAddress":
        return <BusinessIcon />;
      case "deliveryBy":
        return <CalendarTodayIcon />;
      case "deliveryFrom":
        return <CalendarTodayIcon />;
      case "deliveryLatitude":
        return <LocationSearchingIcon />;
      case "deliveryLongitude":
        return <LocationSearchingIcon />;
      case "deliveryName":
        return <InfoOutlinedIcon />;
      case "depot":
        return <InfoOutlinedIcon />;
      case "driverName":
        return <InfoOutlinedIcon />;
      case "invoiceNumber":
        return <InfoOutlinedIcon />;
      case "jobID":
        return <InfoOutlinedIcon />;
      case "loadJobID":
        return <InfoOutlinedIcon />;
      case "orderNumber":
        return <InfoOutlinedIcon />;
      case "priority":
        return <InfoOutlinedIcon />;
      case "product":
        return <InfoOutlinedIcon />;
      case "quantity":
        return <InfoOutlinedIcon />;
      case "specialInstructions":
        return <InfoOutlinedIcon />;
      case "startedAt":
        return <CalendarTodayIcon />;
      case "status":
        return <InfoOutlinedIcon />;
      case "totalAmount":
        return <InfoOutlinedIcon />;
      case "totalTimeTaken":
        return <TimerIcon />;
      case "updatedAt":
        return <CalendarTodayIcon />;
      case "valueOne":
        return <InfoOutlinedIcon />;
      case "vat":
        return <InfoOutlinedIcon />;
      case "vehicleRegNo":
        return <DirectionsCarIcon />;
      default:
        return <InfoIcon />;
    }
  };
  const findKeyName = name => {
    switch (name) {
      case "completedAt":
        return "Completed At";
      case "createdAt":
        return "Created At";
      case "customerAccountRef":
        return "Customer Account Ref";
      case "customerAddress":
        return "Customer Address";
      case "customerEmail":
        return "Customer Email";
      case "customerHomeTelephoneNumber":
        return "Customer Home TelephoneNumber";
      case "customerID":
        return "Customer ID";
      case "customerLatitude":
        return "Customer Latitude";
      case "customerLongitude":
        return "Customer Longitude";
      case "customerMobile":
        return "Customer Mobile";
      case "customerName":
        return "Customer Name";
      case "customerOrderNumber":
        return "Customer Order Number";
      case "deliveryAddress":
        return "Delivery Address";
      case "deliveryBy":
        return "Delivery By";
      case "deliveryFrom":
        return "Delivery From";
      case "deliveryLatitude":
        return "Delivery Latitude";
      case "deliveryLongitude":
        return "Delivery Longitude";
      case "deliveryName":
        return "Delivery Name";
      case "depot":
        return "Depot";
      case "driverName":
        return "Driver Name";
      case "invoiceNumber":
        return "Invoice Number";
      case "jobID":
        return "Job ID";
      case "loadJobID":
        return "Load Job ID";
      case "orderNumber":
        return "Order Number";
      case "priority":
        return "Priority";
      case "product":
        return "Product";
      case "quantity":
        return "Quantity";
      case "specialInstructions":
        return "Special Instructions";
      case "startedAt":
        return "Started At";
      case "status":
        return "Status";
      case "totalAmount":
        return "Total Amount";
      case "totalTimeTaken":
        return "Total Time Taken (in seconds)";
      case "updatedAt":
        return "Updated At";
      case "valueOne":
        return "Value One";
      case "vat":
        return "Vat";
      case "vehicleRegNo":
        return "Vehicle Reg No";
      case "driverComments":
        return "Driver Comments";
      case "litres_collected":
        return "Litres Collected";
      case "print_litres_collected_receipt":
        return "Print Litres Collected Receipt";

      case "jobType":
        return "Job Type";
      case "moneyReceived":
        return "Money Received";
      case "colossusConfirmed":
        return "Colossus Confirmed";
        case "moneyReceivedAt":
          return "Money Received At";
          case "colossusConfirmedAt":
            return "Colossus Confirmed At";
      default:
        return name;
    }
  };

  return (
    <>
      <Grid
        className={classes.grid}
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <div className={classes.tableHeading}>
          <Typography>Details</Typography>
        </div>
        <List className={classes.root} subheader={<li />}>
          {job &&
            Object.keys(job).map(item => (
              <ListItem key={`item-`} className={classes.borderBottom}>
                {findIcon(item)}
                <ListItemText
                  className={classes.texts}
                  primary={`${job[item]}`}
                  secondary={findKeyName(item)}
                />
              </ListItem>
            ))}
        </List>
      </Grid>
    </>
  );
}
