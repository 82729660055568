import React, { useEffect, useContext } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import FormControl from "@material-ui/core/FormControl";
import CardContent from "@material-ui/core/CardContent";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import ArrowBackOutlinedIcon from "@material-ui/icons/ArrowBackOutlined";

import FastfoodIcon from "@material-ui/icons/Fastfood";
import LaptopMacIcon from "@material-ui/icons/LaptopMac";
import HotelIcon from "@material-ui/icons/Hotel";
import RepeatIcon from "@material-ui/icons/Repeat";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import SearchIcon from "@material-ui/icons/Search";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import InputLabel from "@material-ui/core/InputLabel";
import LaunchIcon from "@material-ui/icons/Launch";
import LastPageIcon from "@material-ui/icons/LastPage";
import InputBase from "@material-ui/core/InputBase";
import { fade } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ReassignCard from "./ReassignCard";
import CircularProgress from "@material-ui/core/CircularProgress";
import { axios } from "../../utils/axiosConfig";
import JobSub from "./JobSub";
import JobDetailList from "./JobDetailList";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ConfirmedSnackBar from "../../utils/ConfirmedSnackbar";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker
} from "@material-ui/pickers";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";

import TableHead from "@material-ui/core/TableHead";

import Typography from "@material-ui/core/Typography";
import Input from "@material-ui/core/Input";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import Switch from "@material-ui/core/Switch";
import Chip from "@material-ui/core/Chip";
import SaveIcon from "@material-ui/icons/Save";
import moment from "moment-timezone";
import Card from "@material-ui/core/Card";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import JobTimeLine from "./JobTimeLine";
import PaymentTimeLine from "./PaymentTimeLine";
import JobPictures from "./JobPictures";
import CancelCard from "./CancelCard";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import JobReassignments from "./JobReassginments";
import { UserContextData, UserContext } from "../../Contexts/userContext";
import JobCancel from "./JobCancel";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    background: "rgba(35,35,35, 0.9)",
    padding: "8px"
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },

  startAutoJob: {
    color: "#fff",
    background: "#f1b640",
    width: "auto",
    height: "53px",
    marginTop: "17px",
    marginLeft: "10px",
    fontSize: "16px",
    "&:hover": {
      backgroundColor: "#f1b640",
      color: "#fff"
    }
  },
  removeJobButton: {
    color: "#fff",
    background: "rgba(35,35,35, 0.9)",
    width: "auto",
    height: "53px",
    marginTop: "17px",
    marginLeft: "10px",
    fontSize: "16px",
    "&:hover": {
      background: "rgba(35,35,35, 0.9)",
      color: "#fff"
    }
  },
  cancelJob: {
    color: "rgba(35,35,35, 0.9)",
    // background: "#f44336",
    width: "auto",
    height: "53px",
    marginTop: "17px",
    marginLeft: "10px",
    fontSize: "16px",
    "&:hover": {
      backgroundColor: "rgba(35,35,35, 0.9)",
      color: "#fff"
    }
  },

  status: {
    fontSize: 14,
    color: "#fff",
    marginTop: "2px",
    fontStyle: "italic"
  },
  statusTime: {
    marginTop: 15
  },
  enabledChip: {
    backgroudColor: "green"
  },
  disabledChip: {
    backgroud: "red"
  },
  pos: {
    marginBottom: 12
  },
  linearProgress: {
    marginTop: "15px"
  },
  chip: {
    color: "primary",
    height: "23px"
  },
  jobCardTitle: {
    color: "#f1b640"
  },
  jobCardButton: {
    color: "#fff",
    // borderRadius: '60%',
    fontSize: "12px",
    "&:hover": {
      backgroundColor: "#f1b640"
    }
  },
  jobCardButton2: {
    // color:"#f1b640",
    // borderRadius: '60%',
    fontSize: "12px",
    fontWeight: 600
    // "&:hover": {
    //   backgroundColor: '#ffff'
    // },
    // backgroundColor:"#ffff"
  },
  cardInfo: {
    marginTop: "15px"
  },
  tablePagination: {
    // position: 'sticky'
  },
  grid: {
    padding: "16px",
    paddingTop: "0px"
  },
  subHeading: {
    marginBottom: 20,
    marginTop: 12,
    textAlign: "center",
    fontSize: "20px",
    fontWeight: 600
  },
  alignRight: {
    marginLeft: "auto"
  },
  separator: {
    minHeight: 30
  },
  detail: {
    padding: 10
  },
  multiline: {
    marginTop: 10,
    width: "100%"
  },
  fullWidth: {
    width: "100%"
  },
  circularStyle: {
    width: "100%",
    margin: "auto",
    marginTop: "25%",
    marginBottom: "15%"
  },
  backIcon: {
    fontSize: 34,
    marginRight: 10,
    cursor: "pointer"
  },
  headerStyle: {
    marginRight: 5
  },
  bold: {
    fontWeight: 600
  }
});

function JobDetail(props) {
  const classes = useStyles();
  const { user, logoutUser } = useContext(UserContext);
  const [cancelOpen, setCancelOpen] = React.useState(false);
  const [removeOpen, setRemoveJobOpen] = React.useState(false);
  const [progress, setProgress] = React.useState(false);
  const [changeDriverOpen, setChangeDriverOpen] = React.useState(false);
  const [jobData, setJobData] = React.useState({});
  const [jobPictures, setJobPictures] = React.useState([]);
  const [jobTimeline, setJobTimeLine] = React.useState([]);
  const [paymentTimeline, setPaymentTimeLine] = React.useState([]);
  const [reassignments, setReassignments] = React.useState([]);
  const [cancelJobs, setCancel] = React.useState([]);
  const [removedJobs, setRemovedJobs] = React.useState([]);
  const [removalReason, setRemovalReason] = React.useState("");
  const [cancelReason, setCancelReason] = React.useState("");
  const [changeReason, setChangeReason] = React.useState("");
  const [newDriver, setNewDriver] = React.useState(null);
  const [removeProgress, setRemoveProgress] = React.useState(false);
  const [cancelProgress, setCancelProgress] = React.useState(false);
  const [changeProgress, setChangeProgress] = React.useState(false);
  const [invoiceProgress, showInvoiceProgress] = React.useState(false);
  console.log(user, "useruseruseruser");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleCancelOpen = () => {
    setCancelOpen(true);
  };

  const handleCancelClose = () => {
    setCancelOpen(false);
  };
  const handleRemoveClose = () => {
    setRemoveJobOpen(false);
  };
  const handleChangeDriverClose = () => {
    setChangeDriverOpen(false);
  };

  useEffect(() => {
    getJobByID(props.selectedJob.jobID);
  }, []);

  const getJobByID = id => {
    setProgress(true);
    const url = `job/detail/${id}`;
    axios
      .get(url)
      .then(res => {
        let response = res.data;
        if (response.success === true) {
          setJobData(response.data.job);
          setJobPictures(response.data.images);
          setJobTimeLine(response.data.timelines);
          setPaymentTimeLine(response.data.payments);
          setReassignments(response.data.reAssignJobs);
          setCancel(response.data.cancelJobs);
          setRemovedJobs(response.data.removedJobs);
        } else {
        }
        setProgress(false);
      })
      .catch(function(error) {
        if (error.response.status === 403) {
          logoutUser();
        }
      });
  };
  const handleRemoveReason = event => {
    console.log(event.target.value, "hasdafdadasd");
    setRemovalReason(event.target.value);
  };

  const handleCancelReason = event => {
    console.log(event.target.value, "hasdafdadasd");
    setCancelReason(event.target.value);
  };

  const cancelJob = () => {
    setCancelProgress(true);
    const body = {
      jobID: jobData.jobID,
      driverID: jobData.driverID,
      userID: user.userID,
      comments: cancelReason,
      invoiceNumber: jobData.invoiceNumber,
      cancelMethod: 1,
      accepted: 0
    };
    const url = `job/cancel`;
    axios
      .post(url, body)
      .then(res => {
        let response = res.data;
        setCancelProgress(false);
        setCancelOpen(false);
        if (response.success === true) {
          getJobByID(props.selectedJob.jobID);
          setCancelReason("");
          props.showSnackBar("Job Cancelled");
        } else {
        }
      })
      .catch(function(error) {
        if (error.response.status === 403) {
          logoutUser();
        }
      });
  };
  const removeJob = () => {
    setRemoveProgress(true);
    const body = {
      jobID: jobData.jobID,
      driverID: jobData.driverID,
      invoiceNumber: jobData.invoiceNumber,
      userID: user.userID,
      comments: removalReason,
      accepted: 0
    };
    const url = `job/remove`;
    axios
      .post(url, body)
      .then(res => {
        let response = res.data;
        setRemoveProgress(false);
        setRemoveJobOpen(false);
        if (response.success === true) {
          getJobByID(props.selectedJob.jobID);
          setRemovalReason("");
          props.showSnackBar("Job Cancelled");
        } else {
        }
      })
      .catch(function(error) {
        if (error.response.status === 403) {
          logoutUser();
        }
      });
  };

  const handleChangeReason = event => {
    console.log(event.target.value, "hasdafdadasd");
    setChangeReason(event.target.value);
  };

  const handleDriverChange = (event, value) => {
    console.log(value, "event");
    if (value !== null) {
      setNewDriver(value);
    } else {
      setNewDriver(null);
    }
  };
  const changeDriver = () => {
    if (newDriver == null) {
      props.showSnackBar("Select Driver");
      return;
    }
    console.log(newDriver, "newDriver");
    setChangeProgress(true);
    const body = {
      jobID: jobData.jobID,
      driverID: newDriver.driverID,
      oldDriverID: jobData.driverID,
      invoiceNumber: jobData.invoiceNumber,
      userID: user.userID,
      comments: changeReason,
      driverName: newDriver.driverName
    };
    const url = `job/reassign`;
    axios
      .post(url, body)
      .then(res => {
        let response = res.data;
        if (response.success === true) {
          getJobByID(props.selectedJob.jobID);
        } else {
        }
        setNewDriver(null);
        setChangeReason("");
        setChangeDriverOpen(false);
        setChangeProgress(false);
      })
      .catch(function(error) {
        if (error.response.status === 403) {
          logoutUser();
        }
      });
  };

  const downloadInvoice = () => {
    showInvoiceProgress(true);
    const url = `job/create/invoice/${props.selectedJob.jobID}`;

    axios
      .get(url, {
        responseType: "arraybuffer"
      })
      .then(res => {
        showInvoiceProgress(false);
        if(res.status==204){
          props.showSnackBar("Unable to generate PDF")
        return;
        }
        let blob = new Blob([res.data], { type: "application/pdf" });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `POD-${props.selectedJob.orderNumber}.pdf`;
        link.click();
      })
      .catch(function(error) {
        if (error.response.status === 403) {
          logoutUser();
        }
      });
  };
  return (
    <>
      {progress ? (
        <Grid
          className={classes.grid}
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <CircularProgress size={50} className={classes.circularStyle} />
        </Grid>
      ) : (
        <>
          <Grid
            className={classes.grid}
            container
            direction="row"
            // justify="space-between"
            alignItems="center"
          >
            <ArrowBackOutlinedIcon
              className={classes.backIcon}
              onClick={e => {
                props.jobDetailBack();
              }}
            />
            <div className={classes.headerStyle}>
              <h1>{jobData.deliveryName=="N/A"? jobData.customerName:jobData.deliveryName}</h1>
            </div>
            <Chip
              size="medium"
              style={
                jobData.status === "Completed"
                  ? {
                      backgroundColor: "#e5f9ed",
                      color: "#1bc943",
                      width: "fit-content",
                      padding: "10px",
                      height: "35px",
                      fontSize: "17px",
                      marginTop: "5px",
                      marginLeft: "10px"
                    }
                  : jobData.status === "Completed" ||
                    jobData.status === "Cancelled"
                  ? {
                      backgroundColor: "#fff5f6",
                      color: "#f83245",
                      width: "fit-content",
                      padding: "10px",
                      height: "35px",
                      fontSize: "17px",
                      marginTop: "5px",
                      marginLeft: "10px"
                    }
                  : {
                      backgroundColor: "#fff8e1",
                      color: "#ffc107",
                      width: "fit-content",
                      padding: "10px",
                      height: "35px",
                      fontSize: "17px",
                      marginTop: "5px",
                      marginLeft: "10px"
                    }
              }
              label={jobData.status}
              className={
                "Allocated" === true
                  ? classes.enabledChip
                  : classes.disabledChip
              }
            />

            <div className={classes.alignRight}>
              {(jobData.status === "Allocated" ||
                jobData.status === "Downloaded" ||
                jobData.status === "Reassigned" ||
                jobData.status == "Created") && (
                <>
                  <Button
                    variant="contained"
                    className={classes.removeJobButton}
                    onClick={() => setRemoveJobOpen(true)}
                  >
                    Remove Job
                  </Button>

                  <Button
                    variant="outlined"
                    className={classes.cancelJob}
                    onClick={() => setCancelOpen(true)}
                  >
                    Cancel Job
                  </Button>
                  {jobData.status !== "Created" && (
                    <Button
                      variant="contained"
                      className={classes.startAutoJob}
                      onClick={() => setChangeDriverOpen(true)}
                    >
                      Change Driver
                    </Button>
                  )}
                </>
              )}

              {jobData.status === "Completed" && jobData.jobType.toLowerCase()!=="summary" && (
                <Button
                  variant="contained"
                  className={classes.removeJobButton}
                  onClick={() => downloadInvoice()}
                  disabled={invoiceProgress}
                >
                  {invoiceProgress && (
                    <CircularProgress
                      size={20}
                      className={classes.circularProgress}
                    />
                  )}
                  {!invoiceProgress && "Download POD"}
                </Button>

                // <Button
                //   variant="contained"
                //   className={classes.removeJobButton}
                //   onClick={() => downloadInvoice()}
                // >
                //   Download Invoice
                // </Button>
              )}
            </div>
          </Grid>
          <Grid
            className={classes.grid}
            container
            spacing={0}
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item xs={12} sm={12} md={12}>
              <JobSub jobData={jobData} />
            </Grid>
          </Grid>
          <Grid
            className={classes.grid}
            container
            spacing={0}
            direction="row"
            justify="space-between"
            // alignItems="center"
          >
            <Grid item xs={12} sm={12} md={6} className={classes.detail}>
              <div className={classes.subHeading}>Job Details</div>
              <JobDetailList jobData={jobData} />
            </Grid>
            {jobPictures.length > 0 && (
              <Grid item xs={12} sm={12} md={6} className={classes.detail}>
                <div className={classes.subHeading}>Images</div>
                <JobPictures jobPictures={jobPictures} />
              </Grid>
            )}
            {jobTimeline.length > 0 && (
              <Grid item xs={12} sm={12} md={6}>
                <div className={classes.subHeading}>Timeline</div>
                <JobTimeLine jobTimeline={jobTimeline} />
              </Grid>
            )}
            {paymentTimeline.length > 0 && (
              <Grid item xs={12} sm={12} md={6}>
                <div className={classes.subHeading}>Payments</div>
                <PaymentTimeLine paymentTimeline={paymentTimeline} />
              </Grid>
            )}
            {reassignments.length > 0 && (
              <Grid item xs={12} sm={12} md={6}>
                <div className={classes.subHeading}>Ressignments</div>
                <JobReassignments
                  reassignments={reassignments}
                  allDrivers={props.allDrivers}
                />
              </Grid>
            )}
            {cancelJobs.length > 0 && (
              <Grid item xs={12} sm={12} md={6}>
                <div className={classes.subHeading}>Cancellation</div>
                <JobCancel
                  cancelJobs={cancelJobs}
                  allDrivers={props.allDrivers}
                />
              </Grid>
            )}
            {removedJobs.length > 0 && (
              <Grid item xs={12} sm={12} md={6}>
                <div className={classes.subHeading}>Removed</div>
                <JobCancel
                  cancelJobs={removedJobs}
                  allDrivers={props.allDrivers}
                />
              </Grid>
            )}
          </Grid>
        </>
      )}
      <Dialog
        fullScreen={fullScreen}
        open={removeOpen}
        onClose={handleRemoveClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{"Remove Job?"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you want to Remove job with Invoice No.{" "}
            <span className={classes.bold}>{jobData.invoiceNumber}</span> Please
            write Removal reason
          </DialogContentText>
          <TextField
            value={removalReason}
            className={classes.multiline}
            onChange={handleRemoveReason}
            id="filled-multiline-static"
            label="Removal Reason"
            multiline
            rows={5}
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            className={classes.cancelJob}
            onClick={handleRemoveClose}
          >
            Disagree
          </Button>
          <Button
            variant="contained"
            className={classes.startAutoJob}
            onClick={() => removeJob()}
            disabled={removeProgress}
          >
            {removeProgress && (
              <CircularProgress
                size={20}
                className={classes.circularProgress}
              />
            )}
            {!removeProgress && "Remove Job"}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullScreen={fullScreen}
        open={cancelOpen}
        onClose={handleCancelClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{"Cancel Job?"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you want to cancel job with Invoice No.{" "}
            <span className={classes.bold}>{jobData.invoiceNumber}</span> Please
            write cancelletion reason
          </DialogContentText>
          <TextField
            value={cancelReason}
            className={classes.multiline}
            onChange={handleCancelReason}
            id="filled-multiline-static"
            label="Cancel Reason"
            multiline
            rows={5}
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            className={classes.cancelJob}
            onClick={handleCancelClose}
          >
            Disagree
          </Button>
          <Button
            variant="contained"
            className={classes.startAutoJob}
            onClick={() => cancelJob()}
            disabled={cancelProgress}
          >
            {cancelProgress && (
              <CircularProgress
                size={20}
                className={classes.circularProgress}
              />
            )}
            {!cancelProgress && "Cancel Job"}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullScreen={fullScreen}
        open={changeDriverOpen}
        onClose={handleChangeDriverClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Change Job Driver?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you want to change the driver of the job with Invoice No.{" "}
            <span className={classes.bold}>{jobData.invoiceNumber}</span>
          </DialogContentText>
          <Autocomplete
            className={classes.multiline}
            id="combo-box-demo"
            options={props.allDrivers.filter(
              item => item.driverID !== jobData.driverID
            )}
            onChange={(event, value) => handleDriverChange(event, value)}
            getOptionLabel={option => option.driverName}
            renderInput={params => (
              <TextField
                {...params}
                label="New Driver Name"
                variant="outlined"
              />
            )}
          />
          <TextField
            className={classes.multiline}
            id="filled-multiline-static"
            label="Change Reason"
            onChange={handleChangeReason}
            multiline
            rows={5}
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            className={classes.cancelJob}
            onClick={handleChangeDriverClose}
          >
            Disagree
          </Button>
          <Button
            variant="contained"
            className={classes.startAutoJob}
            disabled={changeProgress}
            onClick={() => changeDriver()}
          >
            {changeProgress && (
              <CircularProgress
                size={20}
                className={classes.circularProgress}
              />
            )}
            {!changeProgress && "Change Driver"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default JobDetail;
