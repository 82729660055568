import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import {
  Grid,
  CssBaseline,
  Paper,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Box,
} from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import pattersonCoverImage from "../../images/patterson-cover.jpg";
import pattersonLogo from "../../images/patterson-oil.jpg";
import { UserContext, UserContextData } from "../../Contexts/userContext";
import { axios } from "../../utils/axiosConfig";
import { LoginResponse } from "../../NetworkInterfaces/loginResponse";

// https://emailregex.com/ for the email regex

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100vh",
    },

    image: {
      backgroundImage: `url(${pattersonCoverImage})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
    },

    paper: {
      margin: theme.spacing(8, 4),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },

    signin: {
      marginTop: theme.spacing(3),
    },

    img: {
      maxWidth: "100%",
    },

    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },

    submit: {
      margin: theme.spacing(3, 0, 2),
    },

    error: {
      color: theme.palette.error.main,
      fontSize: theme.typography.fontSize,
    },
  })
);

function loginRequest({
  email,
  password,
}: {
  email: string;
  password: string;
}) {
  return axios
    .post("/user/login", {
      email,
      password,
    })
    .then((x) => {
      const res: LoginResponse = x.data;
      if (res.success === false) throw new Error(res.message.toString());
      return res;
    });
}

export function SignIn() {
  const classes = useStyles();
  const { loginUser } = useContext<UserContextData>(UserContext);

  const [mutate, { isError, isLoading }] = useMutation(loginRequest);

  const signIn = async (user: any) => {
    try {
      const x = await mutate(user);
      console.log(x);
      if (x&&x.token && x.data) {
        loginUser({ token: x.token, ...x.data });
      } else {
        throw new Error("token not inside response");
      }
    } catch {
      // already handled by useMutation
    }
  };

  const { register, errors, handleSubmit } = useForm();

  const onSubmit = async (data: any) => {
    console.log(data);
    await signIn(data);
  };

  return (
    <Grid container className={classes.root}>
      <CssBaseline>
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <img
              className={classes.img}
              src={pattersonLogo}
              alt="patterson oil co mpany logo"
            />
            <Typography className={classes.signin} component="h1" variant="h5">
              Sign in to Dashboard
            </Typography>
            <form
              className={classes.form}
              onSubmit={handleSubmit(onSubmit)}
              noValidate
            >
              <TextField
                error={!!errors.email}
                helperText={errors.email?.message}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                disabled={isLoading}
                inputRef={register({
                  required: "Email is required",
                  pattern: {
                    value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Valid email is required",
                  },
                })}
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                error={!!errors.password}
                helperText={errors.password?.message}
                variant="outlined"
                margin="normal"
                inputRef={register({
                  required: "Password is required",
                  minLength: {
                    value: 4,
                    message: "Password must be minimum 4 characters long",
                  },
                })}
                required
                disabled={isLoading}
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />

              <Button
                type="submit"
                fullWidth
                disabled={isLoading}
                disableElevation
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Sign In{" "}
                {isLoading && (
                  <>
                    {" "}
                    &nbsp; <CircularProgress color="inherit" size={20} />{" "}
                  </>
                )}
              </Button>

              <Typography color="error">
                <Box fontWeight={500} m={1}>
                     {isError&&"Login Failed"}
                </Box>
              </Typography>
            </form>
          </div>
        </Grid>
      </CssBaseline>
    </Grid>
  );
}
