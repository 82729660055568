import React from "react";
import { Grid, CssBaseline, Typography } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100vh",
    },

  })
);

export default function NotFound() {

    const classes = useStyles()
  return (
    <Grid className={classes.root} container justify="center" alignItems="center">
      <CssBaseline>
        <Grid item>
          <Typography variant="h2"  gutterBottom>404 Not found</Typography>
          <Typography>The page you are looking for does not exist !</Typography>
        </Grid>
      </CssBaseline>
    </Grid>
  );
}
