import React, { useState } from "react";
import { UserData } from "../NetworkInterfaces/loginResponse";
import { useHistory } from "react-router-dom";
// import ConfirmationDialog from "../utils/Dialog";
import { queryCache } from "react-query";

const UserContext = React.createContext<any>(null);
UserContext.displayName = "UserContext";
const { Consumer: UserContextConsumer } = UserContext;

export interface UserTokenData extends UserData {
  token: string;
}

export interface UserContextData {
  user: UserTokenData | null;
  loginUser: (data: UserTokenData) => void;
  logoutUser: () => void;
}

function UserContextProvider(props: any) {
  const tokenKey = "userToken";
  const [user, setUser] = useState<UserTokenData | null>(
    JSON.parse("" + localStorage.getItem(tokenKey))
  );
  const history = useHistory();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    queryCache.clear()
    setOpen(false);
    localStorage.removeItem(tokenKey);
    setUser(null);
  };


  const loginUser = (data: UserTokenData) => {
    console.log(data);
    setUser(data);
    localStorage.setItem(tokenKey, JSON.stringify(data));
    history.replace("/");
  };

  const logoutUser = () => {
    console.log("logoutUser");
    handleLogout();
    setOpen(true);
  };

  const data: UserContextData = {
    user,
    loginUser,
    logoutUser,
  };

  return (
    <UserContext.Provider value={data}>
      <>
        {/* display dialog */}
        {/* <ConfirmationDialog
         text="Are you sure you want to logout ?"
          isOpen={open}
          handleClose={handleClose}
          handleAgree={handleLogout}
        /> */}
        {props.children}
      </>
    </UserContext.Provider>
  );
}

export { UserContextProvider, UserContext, UserContextConsumer };
