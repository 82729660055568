import React, { useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Pagination from "@material-ui/lab/Pagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import LaunchIcon from "@material-ui/icons/Launch";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import TableHead from "@material-ui/core/TableHead";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Input from "@material-ui/core/Input";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Chip from "@material-ui/core/Chip";
import SaveIcon from "@material-ui/icons/Save";
import { axios } from "../../utils/axiosConfig";
import { UserContextData, UserContext } from "../../Contexts/userContext";
import moment from "moment-timezone";
export default function PaymentTable(props) {
  const classes = props.classes;

  //   const { table } = props.table;
  const [page, setPage] = React.useState(0);
  const { user, logoutUser } = useContext(UserContext);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [isCellHovered, setCellHover] = React.useState(null);
  const [cellValue, setCellValue] = React.useState(null);
  const [moneySwitchValue, setMoneySwitchValue] = React.useState(null);
  const [paymentConfirmedSwitchValue, setPaymentConfirmedSwitchValue] = React.useState(null);
  const [updatedData, setDataUpdate] = React.useState([]);
  const [updateTable, setUpdateTable] = React.useState(false);
  const [saveProgress, setSaveProgress] = React.useState(false);
  const [rowData, setRowData] = React.useState(props.rows);
  const [dataForFilter, setDataForFilter] = React.useState(props.rows);
  const [inputFocus, setInputFocus] = React.useState(true);
  // const [inputRef, setInputFocus] = useRef(null)
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, props.rows.length - page * rowsPerPage);

  const cellHover = cellValue => {
    setCellHover(cellValue);
  };
  const cellLeave = cellValue => {
    setCellHover(null);
  };

  useEffect(() => {
    setRowData(props.rows);
    setMoneySwitchValue(null);
    setPaymentConfirmedSwitchValue(null);
    setUpdateTable(false);
  }, [props.rows]);

  const handleSwitchChange = (event, paymentID, feild, row) => {

    const index2 = rowData.findIndex(e => e.paymentID === paymentID);
    const newRows = [...rowData];
    let newRowValue = { ...newRows[index2] };
    newRowValue.paymentConfirmedProgress = true;
    newRows[index2] = newRowValue;
    setRowData(newRows);
    let newElement = {};

    newElement = {
      paymentID: paymentID,
      paymentConfirmed: event.target.checked ? 1 : 0,
      jobID:row.jobID,
      paymentConfirmedAt: moment()
        .tz("Europe/London")
        .format("YYYY-MM-DD hh:mm:ss"),
        paymentConfirmedBy: user.firstName + " " + user.lastName
    };

    setSaveProgress(true);
    let url = "job/payments/status";
    let body = {
      payments: [newElement]
    };

    axios.post(url, body).then(res => {
      setSaveProgress(false);
      let response = res.data;
      if (response.success === true) {
        props.showSnackBar("Data Saved");
        props.updatePayments();
      } else {
        props.showSnackBar("Unable to Save Data");
        props.updatePayments();
      }
    });

    //money  recieved
    // if (!updateTable) {
    //   setUpdateTable(true);
    // }

    // let newElement = {};
    // newElement = {
    //   paymentID: paymentID,
    //   moneyReceived: event.target.checked,
    //   colossusConfirmed: row.colossusConfirmed
    // };

    // const index = updatedData.findIndex(e => e.paymentID === paymentID);
    // if (index == -1) {
    //   setDataUpdate([...updatedData, newElement]);
    // } else {
    //   const newData = [...updatedData];
    //   let newDataValue = { ...newData[index] };
    //   newDataValue.moneyReceived = event.target.checked;
    //   newData[index] = newDataValue;
    //   setDataUpdate(newData);
    // }

    // const index2 = rowData.findIndex(e => e.paymentID === paymentID);
    // const newRows = [...rowData];
    // let newRowValue = { ...newRows[index2] };
    // newRowValue[feild] = event.target.checked;
    // newRows[index2] = newRowValue;
    // setRowData(newRows);

    // const index3 = dataForFilter.findIndex(e => e.paymentID === paymentID);
    // const newRowsFilter = [...dataForFilter];
    // let newRowValueFilter = { ...newRowsFilter[index3] };
    // newRowValueFilter[feild] = event.target.checked;
    // newRowsFilter[index3] = newRowValueFilter;
    // setDataForFilter(newRowsFilter);
  };

  // const handleCurrentValueSwitchChange = (event, paymentID, feild, row) => {
  //   // if (!updateTable) {
  //   //   setUpdateTable(true);
  //   // }

  //   console.log(row, "row");
  //   let newElement = {};

  //   newElement = {
  //     paymentID: paymentID,
  //     colossusConfirmed: event.target.checked ? 1 : 0,
  //     logBookConfirmedAt: moment()
  //       .tz("Europe/London")
  //       .format("YYYY-MM-DD hh:mm:ss"),
  //     logBookConfirmedBy: user.firstName + " " + user.lastName
  //   };

  //   setSaveProgress(true);
  //   let url = "job/status";
  //   let body = {
  //     jobs: [newElement]
  //   };

  //   axios.post(url, body).then(res => {
  //     setSaveProgress(false);
  //     let response = res.data;
  //     if (response.success === true) {
  //       props.showSnackBar("Data Saved");
  //       props.updateJob();
  //     } else {
  //       props.showSnackBar("Unable to Save Data");
  //       props.updateJob();
  //     }
  //   });

  //   // const index = updatedData.findIndex(e => e.paymentID === paymentID);
  //   // if (index == -1) {
  //   //   setDataUpdate([...updatedData, newElement]);
  //   // } else {
  //   //   const newData = [...updatedData];
  //   //   let newDataValue = { ...newData[index] };
  //   //   newDataValue.colossusConfirmed = event.target.checked;
  //   //   newData[index] = newDataValue;
  //   //   setDataUpdate(newData);
  //   // }
  //   // const index2 = rowData.findIndex(e => e.paymentID === paymentID);
  //   // console.log(rowData, feild, "indexFound");
  //   // const newRows = [...rowData];
  //   // let newRowValue = { ...newRows[index2] };
  //   // newRowValue[feild] = event.target.checked;
  //   // newRows[index2] = newRowValue;
  //   // setRowData(newRows);

  //   // const newRowsFilter = [...dataForFilter];
  //   // let newRowValueFilter = { ...newRowsFilter[index2] };
  //   // newRowValueFilter[feild] = event.target.checked;
  //   // newRowsFilter[index2] = newRowValueFilter;
  //   // setDataForFilter(newRowsFilter);
  // };

  const handleSearch = event => {
    // console.log(inputRef,event,"inputRef")
    // inputRef.current.blur();
    setInputFocus(false);
    let rows = dataForFilter;
    const newData = rows.filter(item => {
      if (!event.target.value) {
        return item;
      }
      if (
        item.orderNumber
          .toLowerCase()
          .includes(event.target.value.toLowerCase())
      ) {
        return item;
      }
    });

    setRowData(newData);
  };

  const saveData = () => {
    const updatedDataColossus = updatedData.map(data =>
      data.colossusConfirmed === true
        ? { ...data, colossusConfirmed: 1 }
        : { ...data, colossusConfirmed: 0 }
    );

    const updatedDataMoney = updatedDataColossus.map(data =>
      data.moneyReceived === true
        ? { ...data, moneyReceived: 1 }
        : { ...data, moneyReceived: 0 }
    );

    console.log(updatedDataMoney, "updatedDataupdatedDataupdatedData");

    setSaveProgress(true);
    let url = "job/status";
    let body = {
      jobs: updatedDataMoney
    };

    axios.post(url, body).then(res => {
      setSaveProgress(false);
      let response = res.data;
      if (response.success === true) {
        props.showSnackBar("Data Saved");
        props.updateJob();
      } else {
        props.showSnackBar("Unable to Save Data");
        props.updateJob();
      }
    });
  };

  return (
    <>
      <div className={classes.tableHeading}>
        <Typography>{props.tableName}</Typography>
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            onChange={e => handleSearch(e)}
            placeholder="Search Order Number… "
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput
            }}
            inputProps={{ "aria-label": "search" }}
          />
        </div>
      </div>

      <TableContainer component={Paper} className={classes.tableContainer} >
        <Table
          aria-label="custom pagination table"
          className={classes.table}
          style={{ width: "auto", margin:'10px', tableLayout: "auto" }}
        >
          <TableHead>
            <TableRow className={classes.tableHead}>
              {props.tableColumns.map(column => (
                <TableCell
                  className={classes.tableHeadCol}
                  variant="head"
                  align="center"
                  style={{ width: "max-content" }}
                >
                  {column.title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableHead}>
            {rowData.map(row => (
              <TableRow key={row.name}>
                {props.tableColumns.map(column => (
                  <TableCell
                    className={
                      isCellHovered == row.postCode + "-" + column.field &&
                      column.field !== "isEnabled" &&
                      column.field !== "currentlyPricing"
                        ? classes.tableRowHovered
                        : classes.tableRow
                    }
                    onMouseOver={() =>
                      cellHover(row.paymentID + "-" + column.field)
                    }
                    onMouseLeave={() =>
                      cellLeave(row.paymentID + "-" + column.field)
                    }
                    component="th"
                    scope="row"
                    align="center"
                  >
                    {cellValue != row.paymentID + "-" + column.field && (
                      <div className={classes.tableCellClass}>
                        {column.field !== "paymentConfirmed" &&
                          column.field !== "colossusConfirmed" &&
                          (column.field === "addedAt" ? (
                            moment(row[column.field])
                              .tz("Europe/London")
                              .format("DD/MM/YYYY hh:mm A")
                          ) : column.field === "action" ? (
                            <div className={classes.tableBold}>
                              <LaunchIcon
                                className={classes.launchIcon}
                                onClick={e => props.handleJobDetailView(row)}
                              />
                            </div>
                          ) : column.field === "status" ? (
                            <Chip
                              size="small"
                              style={
                                row[column.field] === "Completed"
                                  ? {
                                      backgroundColor: "#e5f9ed",
                                      color: "#1bc943",
                                      fontWeight: 600,
                                      padding: "5px"
                                    }
                                  : row[column.field] === "Suspended" ||
                                    row[column.field] === "Cancelled"
                                  ? {
                                      backgroundColor: "#fff5f6",
                                      color: "#f83245",
                                      fontWeight: 600,
                                      padding: "5px"
                                    }
                                  : {
                                      backgroundColor: "#fff8e1",
                                      color: "#ffc107",
                                      fontWeight: 600,
                                      padding: "5px"
                                    }
                              }
                              label={row[column.field]}
                              className={
                                row[column.field] === true
                                  ? classes.enabledChip
                                  : classes.disabledChip
                              }
                            />
                          ) : column.field === "product" ? (
                            <Chip
                              size="small"
                              style={
                                row[column.field] === "Kerosene 28 Secs"
                                  ? {
                                      backgroundColor: "#009dff",
                                      color: "#bfe6ff",
                                      fontWeight: 600,
                                      padding: "5px"
                                    }
                                  : row[column.field] ===
                                      "Kerosene +FBoost13" ||
                                    row[column.field] === "Kerosene +FBoost5" ||
                                    row[column.field] ===
                                      "Kerosene +FBoost20" ||row[column.field] ===
                                      "Kerosene +FBoost10" ||
                                    row[column.field] === " Kerosene +FBoost3"
                                  ? {
                                      backgroundColor: "#f15a2f",
                                      color: "#fff5f6",
                                      fontWeight: 600,
                                      padding: "5px"
                                    }
                                  : row[column.field] ===
                                      "Essar Marked Gasoil (undosed)" ||
                                    row[column.field] === "Gas Oil 35 Secs"
                                  ? {
                                      backgroundColor: "#f83245",
                                      color: "#fff5f6",
                                      fontWeight: 600,
                                      padding: "5px"
                                    }
                                  : {
                                      backgroundColor: "#ffc107",
                                      color: "#fff8e1",
                                      fontWeight: 600,
                                      padding: "5px"
                                    }
                              }
                              label={row[column.field]}
                              className={
                                row[column.field] === true
                                  ? classes.enabledChip
                                  : classes.disabledChip
                              }
                            />
                          ) : row[column.field] ? (
                            column.field === "orderNumber" ||
                            column.field === "customerName" ? (
                              <div className={classes.tableBold}>
                                {row[column.field]}
                              </div>
                            ) : (
                              row[column.field]
                            )
                          ) : row[column.field] === 0 ? (
                            "0"
                          ) : (
                            "N/A"
                          ))}
                        {(column.field === "paymentConfirmed" ) &&
                          paymentConfirmedSwitchValue !=
                            row.paymentID + "-" + column.field &&
                           (
                            <Chip
                              size="small"
                              style={
                                row[column.field] === true
                                  ? {
                                      backgroundColor: "#e5f9ed",
                                      color: "#1bc943"
                                    }
                                  : {
                                      backgroundColor: "#fff5f6",
                                      color: "#f83245"
                                    }
                              }
                              label={
                                row[column.field] === true
                                  ? "Confirmed "
                                  :" Not Confirmed"
                              }
                              className={
                                row[column.field] === true
                                  ? classes.enabledChip
                                  : classes.disabledChip
                              }
                            />
                          )}
                     

                        {isCellHovered == row.paymentID + "-" + column.field &&
                          // row.isEnabled == true ||
                          (column.field == "paymentConfirmed" ) &&
         
                          paymentConfirmedSwitchValue !=
                            row.paymentID + "-" + column.field  && (
                            //
                            <EditIcon
                              className={classes.editIconChip}
                              onClick={() => {
                                column.field === "paymentConfirmed"
                                  ? setPaymentConfirmedSwitchValue(
                                      row.paymentID + "-" + column.field
                                    )
                                  : column.field === "moneyReceived"
                                  ? setMoneySwitchValue(
                                      row.paymentID + "-" + column.field
                                    )
                                  : setCellValue(
                                      row.postCode + "-" + column.field
                                    );

                                setInputFocus(true);
                              }}
                            />
                          )}
                      </div>
                    )}

                    {paymentConfirmedSwitchValue == row.paymentID + "-" + column.field &&!row.paymentConfirmedProgress&& (
                      <div className={classes.tableCellClass}>
                        <form noValidate autoComplete="off">
                          <Grid
                            component="label"
                            container
                            alignItems="center"
                            spacing={1}
                            className={classes.switchGrid}
                          >
                            <Grid item>Not Confirmed</Grid>
                            <Grid item>
                              <AntSwitch
                                checked={row[column.field]}
                                onChange={event =>
                                  handleSwitchChange(
                                    event,
                                    row.paymentID,
                                    column.field,
                                    row
                                  )
                                }
                                name="checkedC"
                              />
                            </Grid>
                            <Grid item>Confirmed</Grid>
                          </Grid>
                        </form>
                      </div>
                    )}
                    {paymentConfirmedSwitchValue == row.paymentID + "-" + column.field && row.paymentConfirmedProgress&& (
                      <div className={classes.tableCellClass}>
                          <CircularProgress
                            size={20}
                            className={classes.circularProgress}
                            />
                      </div>
                    )}
                 
                  </TableCell>
                ))}
              </TableRow>
            ))}
            {rowData.length === 0 && (
              <Typography
                variant="h6"
                component="h6"
                className={classes.noDataFoundText}
              >
                No Data Found
              </Typography>
            )}

            {/* {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
               */}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={classes.displayFlex}>
        {updateTable && (
          <Button
            variant="contained"
            className={classes.saveData}
            // disabled={saveProgress}
            startIcon={!saveProgress && <SaveIcon />}
            onClick={() => saveData()}
          >
            {!saveProgress && "Save"}
            {saveProgress && (
              <CircularProgress
                size={20}
                className={classes.circularProgress}
              />
            )}
          </Button>
        )}
        <div className={classes.tableFooter}>
          <Pagination
            count={props.totalPages}
            variant="outlined"
            onChange={props.handlePageChange}
          />
        </div>
      </div>
    </>
  );
}

const AntSwitch = withStyles(theme => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: "flex"
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main
      }
    }
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none"
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white
  },
  checked: {}
}))(Switch);
