import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import Chip from "@material-ui/core/Chip";
import DeleteIcon from "@material-ui/icons/Delete";
import TableChartIcon from "@material-ui/icons/TableChart";
import DoneIcon from "@material-ui/icons/Done";
import GetAppIcon from "@material-ui/icons/GetApp";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment-timezone";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import Input from "@material-ui/core/Input";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import { axios } from "../../utils/axiosConfig";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import Chart from "react-apexcharts";

// const chartData = {
//   options: {
//     fill: {
//         colors: ['rgba(0, 171, 85, 0.85)']
//       },
//     chart: {
//       id: "basic-bar",
//       toolbar: {
//         show: false
//       },
//       zoom: {
//         enabled: false
//       },

//       colors: ['rgba(35,35,35, 0.9)', 'rgba(35,35,35, 0.9)'],
//       foreColor: 'rgba(35,35,35, 0.9)',
//       stroke: {
//         show: true,
//         width: 10,
//         colors: ['transparent']
//       },
//     },
//     plotOptions: {
//         bar: {
//           horizontal: false,
//           borderRadius: 2
//         },
//       },
//     grid: {
//       show: false,
//       xaxis: {
//         lines: {
//           show: false
//         }
//       },
//       yaxis: {
//         lines: {
//           show: false
//         }
//       }
//     },
//     xaxis: {
//       labels: {
//         show: false
//       },
//       enabled: false,
//       categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
//       axisBorder: {
//         show: false
//       },
//       axisTicks: {
//         show: false
//       }
//     },
//     yaxis: {
//       labels: {
//         show: false
//       }
//     },
//     dataLabels: {
//       enabled: false
//     }
//   },
//   series: [
//     {
//       name: "series-1",
//       data: [30, 40, 45, 50, 49, 60, 70, 91]
//     }
//   ],

// };
const useStyles = makeStyles({
  root: {
    // background: "rgba(35,35,35, 0.9)"
    // padding: "8px"
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  titleDiv: {
    display: "flex"
  },
  status: {
    fontSize: 14,
    color: "#fff",
    marginTop: "2px",
    fontStyle: "italic"
  },
  statusTime: {
    marginTop: 15
  },
  pos: {
    marginBottom: 12
  },
  linearProgress: {
    marginTop: "15px"
  },
  chip: {
    color: "primary",
    height: "23px"
  },
  jobCardTitle: {
    color: "rgba(35,35,35, 0.9)",
    fontWeight: 600,
    lineHeight: "1.57143",
    fontSize: "0.875rem",
    margin: "none"
  },
  jobCardPrice: {
    color: "rgba(35,35,35, 0.9)",
    fontSize: "2rem",
    marginTop: "7px",
    marginLeft: "7px",
    // fontWeight: '600',
    letterSpacing: "0.1rem",
    lineHeight: 1.5
  },
  icon: {
    color: "#fff",
    margin: "4px 4px"
  },
  oilgasIcon: {
    margin: "auto",
    marginRight: "5px",
    color: "#e8e8e8",
    width: "65px",
    height: "40px"
  },
  editIcon: {
    color: "#fff",
    fontSize: "20px",
    cursor: "pointer",
    marginRight: "20px"
  },
  circularProgress: {
    width: "20px !important",
    height: "20px !important"
  },
  closeIcon: {
    color: "#fff",
    fontSize: "20px",
    cursor: "pointer",
    marginRight: "20px",
    marginTop: "20px"
  },
  center: {
    // background: "rgba(35,35,35, 0.9)",
    // padding: 16,
    // display: "flex",
    backgroundColor: "rgb(255, 255, 255)",
    color: "rgba(35,35,35, 0.9)",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    backgroundImage: "none",
    overflow: "hidden",
    boxShadow:
      "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
    borderRadius: "8px",
    position: "relative",
    zIndex: 0,
    display: "flex",
    alignItems: "center",
    padding: "24px"
  },
  inputFeild: {
    color: "#fff",
    fontSize: "32px",
    background: "none",
    textAlign: "center",
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none"
    }
  },
  jobCardButton: {
    color: "#fff",
    // borderRadius: '60%',
    fontSize: "12px",
    "&:hover": {
      backgroundColor: "#f1b640"
    }
  },

  jobCardButton2: {
    // color:"#f1b640",
    // borderRadius: '60%',
    fontSize: "12px",
    fontWeight: 600
    // "&:hover": {
    //   backgroundColor: '#ffff'
    // },
    // backgroundColor:"#ffff"
  },
  cardInfo: {
    marginTop: "15px"
  },
  tablePagination: {
    // position: 'sticky'
  },
  borderLeft: {
    borderLeft: "1px solid gray"
  },
  borderRight: {
    // paddingTop: "25px",
    // paddingBottom: "15px",
    // paddingLeft: "15px"
  },
  stackRoot: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: "16px",
    marginBottom: "8px"
  },
  stackIcon: {
    width: "24px",
    height: "24px",
    display: "flex",
    borderRadius: "50%",
    alignItems: "center",
    justifyContent: "center",
    color: "rgb(84, 214, 44)",
    backgroundColor: "rgba(84, 214, 44, 0.16)"
  },
  stackIconDetail: {
    padding: 3
  },
  stackSubtitle: {
    color: "rgba(35,35,35, 0.9)",
    fontWeight: "600",
    lineHeight: "1.57143",
    fontSize: "0.875rem",
    margin: "0px 0px 0px 8px"
  },
  chartRoot: {
    margin: "0px 0px 0px auto"
  },
  cardSubTitle: {
    fontWeight: 400,
    lineHeight: "1.57143",
    fontSize: "0.875rem",
    // textAlign:"left",
    // margin: '4px 0px 0px',
    color: "rgb(99, 115, 129)",
    display: "block"
  },
});

function capitalizeFirstLetter([first, ...rest], locale = navigator.language) {
  return [first.toLocaleUpperCase(locale), ...rest].join("");
}

function PaymentSubs(props) {
  console.log(props.jobData, "props");
  const classes = useStyles();

  // countOfPayments={countOfPayments}
  // countOfPaymentsByLastWeek={countOfPaymentsByLastWeek}
  // paymentsForChartList={paymentsForChartList}
  // sumOfPayments={sumOfPayments}
  // sumOfPaymentsByDriver={sumOfPaymentsByDriver}
  // sumOfPaymentsByLastWeek={sumOfPaymentsByLastWeek}
  // sumOfPaymentsByType={sumOfPaymentsByType}

  const [countOfPayments, setCountOfPayments] = React.useState(0);
  const [sumOfPayments, setSumOfPayments] = React.useState(0);

  useEffect(() => {
    setCountOfPayments(props.countOfPayments);
  }, [props.countOfPayments]);
  useEffect(() => {
    setSumOfPayments(props.sumOfPayments);
  }, [props.sumOfPayments]);

  return (
    <Grid container direction="row" spacing={2} alignItems="center">
      <Grid item xs={12} sm={6} md={6} lg={6} className={classes.borderRight}>
        <Card className={classes.center}>
          {/* <CustomerAccountRefIcon className={classes.oilgasIcon} /> */}
          <div>
            <Typography
              variant="h6"
              component="h6"
              className={classes.jobCardTitle}
            >
              Total Payments Made
            </Typography>
            {/* <div className={classes.stackRoot}>
              <div className={classes.stackIcon}>
                <TrendingUpIcon className={classes.stackIconDetail} />
              </div>
              <Typography variant="subtitle2" className={classes.stackSubtitle}>
                +2.6%
              </Typography>
            </div> */}
            <Typography
              variant="h3"
              component="h3"
              className={classes.jobCardPrice}
            >
              {countOfPayments}
            </Typography>
          </div>
          <div className={classes.chartRoot}>
            {props.countOfPaymentsByLastWeekChartOption.series &&
              props.countOfPaymentsByLastWeekChartOption.series.length > 0 && (
                <Chart
                  options={props.countOfPaymentsByLastWeekChartOption.options}
                  series={props.countOfPaymentsByLastWeekChartOption.series}
                  type="bar"
                  width="100"
                  height="100"
                />
              )}
          </div>
        </Card>
      </Grid>

      <Grid item xs={12} sm={6} md={6} lg={6} className={classes.borderRight}>
        <Card className={classes.center}>
          {/* <CustomerAccountRefIcon className={classes.oilgasIcon} /> */}
          <div>
            <Typography
              variant="h6"
              component="h6"
              className={classes.jobCardTitle}
            >
              Total Payments Amount
            </Typography>
            {/* <div className={classes.stackRoot}>
              <div className={classes.stackIcon}>
                <TrendingUpIcon className={classes.stackIconDetail} />
              </div>
              <Typography variant="subtitle2" className={classes.stackSubtitle}>
                +3.6%
              </Typography>
            </div> */}
            <Typography
              variant="h3"
              component="h3"
              className={classes.jobCardPrice}
            >
            £{sumOfPayments}
            </Typography>
          </div>
          <div className={classes.chartRoot}>
            
          {props.sumOfPaymentsByLastWeekChartOption.series &&
              props.sumOfPaymentsByLastWeekChartOption.series.length > 0 && (
                <Chart
                  options={props.sumOfPaymentsByLastWeekChartOption.options}
                  series={props.sumOfPaymentsByLastWeekChartOption.series}
                  type="bar"
                  width="100"
                  height="100"
                />
              )}
             

            {/* <Chart
              options={chartData.options}
              series={chartData.series}
              type="bar"
              width="100"
              height="100"
            /> */}
          </div>
        </Card>
      </Grid>
    </Grid>
  );
}

export default PaymentSubs;
