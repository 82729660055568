import React,{ useContext} from "react";
import UserTable from "../../Users/Users";
import {
  Button,
  AppBar,
  Tabs,
  Tab,
  CircularProgress,
  makeStyles,
  Theme,
  createStyles,
  Grid,
  Typography
} from "@material-ui/core";
import { UserContext, UserContextData } from "../../../Contexts/userContext";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      padding: theme.spacing(2)
      // marginBottom: theme.spacing(3)
    }
  })
);

export function User() {
  const { user,logoutUser } = useContext<UserContextData>(UserContext);
  const classes = useStyles();

  return (
    <Grid
      className={classes.grid}
      container
      direction="row"
      justify="space-between"
    >
      <Grid item xs={12}>
        <h1>User Management</h1>
      </Grid>
      <Grid item xs={12}>
        <div>
          <UserTable logoutUser={logoutUser} />
        </div>
      </Grid>
    </Grid>
  );
}
