import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import TimelineDot from "@material-ui/lab/TimelineDot";
import moment from "moment-timezone";
import Card from "@material-ui/core/Card";
import FastfoodIcon from "@material-ui/icons/Fastfood";
import ChatIcon from '@material-ui/icons/Chat';
import LaptopMacIcon from "@material-ui/icons/LaptopMac";
import HotelIcon from "@material-ui/icons/Hotel";
import RepeatIcon from "@material-ui/icons/Repeat";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import InfoIcon from "@material-ui/icons/Info";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import LocationSearchingIcon from "@material-ui/icons/LocationSearching";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import TimerIcon from "@material-ui/icons/Timer";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import FormControl from "@material-ui/core/FormControl";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import BusinessIcon from "@material-ui/icons/Business";
import CheckIcon from "@material-ui/icons/Check";
import CardContent from "@material-ui/core/CardContent";
import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import EditIcon from "@material-ui/icons/Edit";
import HelpOutlinedIcon from "@material-ui/icons/HelpOutlined";
import StarOutlinedIcon from "@material-ui/icons/StarOutlined";
const useStyles = makeStyles(theme => ({
  root: {
    background: "rgba(35,35,35, 0.9)",
    padding: "16px 16px"
    // padding: "8px"
  },
  jobCardTitle: {
    margin: "none",
    color: "#fff"
  },
  jobCardPrice: {
    color: "#fff",
    fontSize: "20px",
    // marginTop: "7px",
    marginBottom: "7px"
  },
  paper: {
    padding: "16px 16px"
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main
  }
}));

export default function JobTimeLine(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [timeline, setTimeline] = React.useState([]);
  useEffect(() => {
    setTimeline(props.jobTimeline);
  }, [props.jobTimeline]);

  const findIcon = name => {
    switch (name) {
      case "Customer Information":
        return <InfoIcon />;
        case "Driver Comments":
        return <ChatIcon/>;
      case "Print Ticket":
        return <PrintOutlinedIcon />;
      case "Navigate Map":
        return <LocationSearchingIcon />;
      case "Litres Delivered":
        return <InfoIcon />;
      case "Payments":
        return <HelpOutlinedIcon />;
      case "Customer Sign Required":
        return <InfoIcon />;
      case "Signature":
        return <InfoIcon />;
      case "Map Customer Address":
        return <LocationSearchingIcon />;
      case "Customer GPS":
        return <LocationSearchingIcon />;
      case "Rate Delivery":
        return <StarOutlinedIcon />;
      default:
        return <InfoIcon />;
    }
  };
  const findValue = (name,stepName) => {
    switch (stepName) {
      case "Litres Collected":
        return `Collected Value : ${name.split(",")[0]} - Print Receipt :${name.split(",")[1]}`;
      case "Print Litres Collected Receipt":
        return `Ticket No. : ${name.split(",")[0]} - Sales No. : ${name.split(",")[1]}`;
        default:
            return name;
        }
      };

  return (
    <Timeline align="left">
      {timeline &&
        timeline.map((item, index) => (
          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                Opened :<br />{" "}
                {moment(item.openedAt)
                  .tz("Europe/London")
                  .format("YYYY-MM-DD HH:mm")}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Completed : <br />{" "}
                {moment(item.completedAt)
                  .tz("Europe/London")
                  .format("YYYY-MM-DD HH:mm")}
              </Typography>
            </TimelineOppositeContent>

            <TimelineSeparator>
              <TimelineDot>{findIcon(item.stepName)}</TimelineDot>
              {index !== timeline.length - 1 && <TimelineConnector />}
            </TimelineSeparator>

            <TimelineContent>
              <Card className={classes.root}>
                <div>
                  <Typography
                    variant="h3"
                    component="h3"
                    className={classes.jobCardPrice}
                  >
                    {item.stepName}
                  </Typography>
                  <Typography
                    variant="p"
                    component="p"
                    className={classes.jobCardTitle}
                  >
                    
                    {findValue(item.data,item.stepName)}
                  </Typography>
                  {/* )} */}
                </div>
              </Card>
            </TimelineContent>
          </TimelineItem>
        ))}
    </Timeline>
  );
}
