import React, { useContext, useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  makeStyles,
  createStyles,
  Theme,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer,
  CssBaseline,
  Hidden
} from "@material-ui/core";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import { IS_PROD } from "../../utils/constants";
import { UserContext, UserContextData } from "../../Contexts/userContext";
import MenuIcon from "@material-ui/icons/Menu";
import HelpIcon from "@material-ui/icons/Help";
import { UserRoles } from "../../NetworkInterfaces/loginResponse";
import { User } from "./Components/User";
import { Switch, Route, Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import UserMenu from "./Components/Menu";
import JobList from "../JobList/JobList";
import Help from "../Help/Help";
import logo from "../../images/patterson-oil.jpg";
import Payments from "../Payments/Payments";
const drawerWidth = 90;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      width: "100%"
    },

    // to push the button to the right completely
    title: {
      flexGrow: 1
    },
    logoClass: {
      width: "100%",
      backgroundColor: theme.palette.primary.main,
      height: "65px",
      boxShadow:
        "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"
    },
    logoImage: {
      height: "45px",
      width: "200px",
      padding:'10px',
      // marginTop: theme.spacing(0.5),
      // marginRight: '100%'
      flow:'1'
    },
    iconImage: {
      margin: "auto"
    },
    toolbar: theme.mixins.toolbar,
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0
      }
    },
    content: {
      // flexGrow: 1,
      // padding: theme.spacing(2),
      overflow: "auto",
      overflowY: "hidden",
      marginTop: theme.spacing(8),
      width: "100%"
    },
    drawerPaper: {
      width: drawerWidth
    },
    appBar: {
      [theme.breakpoints.up("sm")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth
      }
    },

    menuButton: {
      marginRight: theme.spacing(2),
      marginLeft: 'auto',
      [theme.breakpoints.up("sm")]: {
        display: "none"
      },
     justifyContent:'right'
    },
    menuButtons: {
      display: "content",
      margin: "auto",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      color: "#e8e8e8"
    },
    menuButtonsIcon: {
      margin: "auto",
      color: "#e8e8e8",
      width: "100%",
      height: "50px"
    },
    menuButtonsIconPrimary: {
      margin: "auto",
      color: theme.palette.primary.main,
      width: "100%",
      height: "50px"
    },
    listDivider: {
      // color: "#e8e8e8"
    },
    menuList: {
      backgroundColor: "rgba(35,35,35, 0.9)",
      height: "100%",
      overflowX: "hidden"
    },
    iconText: {
      // marginTop: theme.spacing(1),
      margin: "auto",
      color: "#e8e8e8"
    },
    iconTextPrimary: {
      margin: "auto",
      color: theme.palette.primary.main
    },
    justifyContent:{
      // justifyContent:'end'
    },
    marginHead:{
      margin:'auto'
    }
  })
);

function UserIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
        <g>
          <path d="M94.677,82.929l-5.013-1.074-.018-.006L77.622,79.277A13.7,13.7,0,0,0,77.75,77.5V71.916A25.516,25.516,0,0,0,89.53,50.43V45.88h2.747a1.749,1.749,0,0,0,1.75-1.75v-6a1.749,1.749,0,0,0-1.75-1.75H91.014A26.953,26.953,0,0,0,70.25,10.473V6.5A1.749,1.749,0,0,0,68.5,4.75h-9A1.75,1.75,0,0,0,57.75,6.5v3.973A26.953,26.953,0,0,0,36.986,36.38H35.723a1.749,1.749,0,0,0-1.75,1.75v6a1.749,1.749,0,0,0,1.75,1.75H38.47v4.55A25.516,25.516,0,0,0,50.25,71.916V77.5a13.7,13.7,0,0,0,.128,1.777L38.354,81.849l-.013,0-5.016,1.074A13.816,13.816,0,0,0,22.44,96.37V121.5a1.75,1.75,0,0,0,1.75,1.75h79.62a1.75,1.75,0,0,0,1.75-1.75V96.37A13.815,13.815,0,0,0,94.677,82.929ZM87.514,36.38H79.25V26.13a1.75,1.75,0,1,0-3.5,0V36.38h-5.5V14.057A23.478,23.478,0,0,1,87.514,36.38ZM61.25,8.25h5.5V36.38h-5.5Zm-3.5,5.808V36.38h-5.5V26.13a1.75,1.75,0,1,0-3.5,0V36.38H40.486A23.476,23.476,0,0,1,57.75,14.058ZM37.473,39.88H90.527v2.5H37.473Zm4.5,10.55V45.88H86.03v4.55a22.03,22.03,0,0,1-44.06,0ZM64,75.95A25.394,25.394,0,0,0,74.25,73.8v3.7a10.25,10.25,0,0,1-20.5,0V73.8A25.394,25.394,0,0,0,64,75.95ZM25.94,96.37a10.3,10.3,0,0,1,8.117-10.019l2.913-.624V119.75H25.94ZM40.47,84.978l10.8-2.312a13.774,13.774,0,0,0,10.984,8.46V119.75H40.47ZM65.75,119.75V91.126a13.772,13.772,0,0,0,10.984-8.46l10.8,2.312V119.75Zm36.31,0H91.03V85.728l2.914.624A10.3,10.3,0,0,1,102.06,96.37Z" />
          <path d="M97.81,102.058a1.749,1.749,0,0,0-1.75,1.75v6.255a1.75,1.75,0,0,0,3.5,0v-6.255A1.75,1.75,0,0,0,97.81,102.058Z" />
          <path d="M55.86,98.08h-9a1.75,1.75,0,0,0,0,3.5h9a1.75,1.75,0,0,0,0-3.5Z" />
        </g>
      </svg>
    </SvgIcon>
  );
}

function JobHistoryIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path d="m121.5 13.6h-115a1.751 1.751 0 0 0 -1.75 1.75v97.308a1.751 1.751 0 0 0 1.75 1.742h115a1.75 1.75 0 0 0 1.75-1.75v-97.304a1.75 1.75 0 0 0 -1.75-1.746zm-113.25 3.5h111.5v10.637h-111.5zm0 93.808v-79.671h111.5v79.663z" />
          <path d="m82.958 19.393a3.024 3.024 0 1 0 3.024 3.024 3.024 3.024 0 0 0 -3.024-3.024z" />
          <path d="m94.792 19.393a3.024 3.024 0 1 0 3.023 3.024 3.024 3.024 0 0 0 -3.023-3.024z" />
          <path d="m106.625 19.393a3.024 3.024 0 1 0 3.023 3.024 3.023 3.023 0 0 0 -3.023-3.024z" />
          <path d="m72.453 87.223h-3.505v-26.253a1.75 1.75 0 0 0 -1.75-1.75h-6.816v-6.052a1.751 1.751 0 0 0 -1.75-1.75h-8.566a1.751 1.751 0 0 0 -1.75 1.75v21.086h-5.066v-13.284a1.751 1.751 0 0 0 -1.75-1.75h-8.566a1.751 1.751 0 0 0 -1.75 1.75v6.59h-6.816a1.75 1.75 0 0 0 -1.75 1.75v17.913h-3.5a1.75 1.75 0 0 0 0 3.5h53.335a1.75 1.75 0 0 0 0-3.5zm-46.335-16.163h5.066v16.163h-5.066zm8.566-1.75v-6.59h5.066v24.5h-5.066zm8.566 8.444h5.066v9.469h-5.066zm8.566-1.754v-21.082h5.066v32.305h-5.066zm8.566 11.219v-24.499h5.066v24.5z" />
          <path d="m112.672 68.274h-29.711a1.75 1.75 0 0 0 0 3.5h29.711a1.75 1.75 0 0 0 0-3.5z" />
          <path d="m112.668 77.748h-29.71a1.75 1.75 0 0 0 0 3.5h29.71a1.75 1.75 0 0 0 0-3.5z" />
          <path d="m112.668 87.223h-29.71a1.75 1.75 0 0 0 0 3.5h29.71a1.75 1.75 0 0 0 0-3.5z" />
          <path d="m112.672 49.325h-29.711a1.75 1.75 0 0 0 0 3.5h29.711a1.75 1.75 0 0 0 0-3.5z" />
          <path d="m82.958 62.3h29.71a1.75 1.75 0 0 0 0-3.5h-29.71a1.75 1.75 0 0 0 0 3.5z" />
        </g>
      </svg>
    </SvgIcon>
  );
}

function PriceListIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      ]{" "}
      <svg viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path d="M47.1,45.347A15.654,15.654,0,1,0,31.443,29.693,15.672,15.672,0,0,0,47.1,45.347Zm-12.014-13.9H46.373l7.978,7.979a12.116,12.116,0,0,1-19.267-7.979Zm24.167-1.75a12.071,12.071,0,0,1-2.426,7.254l-7.977-7.978V17.68A12.162,12.162,0,0,1,59.251,29.693ZM45.348,17.68V27.943H35.084A12.161,12.161,0,0,1,45.348,17.68Z" />
          <path d="M33.193,57.927H94.809a1.75,1.75,0,0,0,0-3.5H33.193a1.75,1.75,0,1,0,0,3.5Z" />
          <path d="M33.19,65.93H80.5a1.75,1.75,0,0,0,0-3.5H33.19a1.75,1.75,0,1,0,0,3.5Z" />
          <path d="M33.19,73.94H75.18a1.75,1.75,0,0,0,0-3.5H33.19a1.75,1.75,0,1,0,0,3.5Z" />
          <path d="M33.19,81.94H72.94a1.75,1.75,0,0,0,0-3.5H33.19a1.75,1.75,0,1,0,0,3.5Z" />
          <path d="M33.19,89.95H73.01a1.75,1.75,0,0,0,0-3.5H33.19a1.75,1.75,0,1,0,0,3.5Z" />
          <path d="M33.19,97.95H75.44a1.75,1.75,0,0,0,0-3.5H33.19a1.75,1.75,0,0,0,0,3.5Z" />
          <path d="M33.19,105.96H81.05a1.75,1.75,0,0,0,0-3.5H33.19a1.75,1.75,0,1,0,0,3.5Z" />
          <path d="M94.809,110.461H33.193a1.75,1.75,0,1,0,0,3.5H94.809a1.75,1.75,0,0,0,0-3.5Z" />
          <path d="M70,37.337h6a1.75,1.75,0,0,0,0-3.5H70a1.75,1.75,0,0,0,0,3.5Z" />
          <path d="M84.562,37.337h10.2a1.75,1.75,0,0,0,0-3.5h-10.2a1.75,1.75,0,0,0,0,3.5Z" />
          <path d="M70,45.347H94.809a1.75,1.75,0,0,0,0-3.5H70a1.75,1.75,0,0,0,0,3.5Z" />
          <path d="M123.25,83.9A21.685,21.685,0,0,0,105.56,62.62V18.5a1.75,1.75,0,0,0-.513-1.237l-12-12A1.752,1.752,0,0,0,91.81,4.75H24.19A1.75,1.75,0,0,0,22.44,6.5v115a1.75,1.75,0,0,0,1.75,1.75h79.62a1.75,1.75,0,0,0,1.75-1.75V105.188A21.686,21.686,0,0,0,123.25,83.9ZM93.56,10.725l6.025,6.025H93.56Zm8.5,109.025H25.94V8.25H90.06V18.5a1.749,1.749,0,0,0,1.75,1.75h10.25V62.262c-.155,0-.309-.012-.464-.012a21.654,21.654,0,0,0,0,43.308c.155,0,.309-.009.464-.012Zm-.464-17.692A18.154,18.154,0,1,1,119.75,83.9,18.175,18.175,0,0,1,101.6,102.058Z" />
          <path d="M115.431,76.584a15.85,15.85,0,0,0-4.6-5.311,1.75,1.75,0,0,0-2.07,2.823,12.318,12.318,0,0,1,3.574,4.129,1.75,1.75,0,0,0,3.092-1.641Z" />
          <path d="M100.341,74.3c-.664.683-6.495,6.828-6.495,11.99a7.75,7.75,0,0,0,15.5,0c0-5.162-5.831-11.307-6.5-11.99A1.8,1.8,0,0,0,100.341,74.3Zm1.255,16.24a4.254,4.254,0,0,1-4.25-4.25c0-2.5,2.456-6,4.25-8.144,1.794,2.148,4.25,5.644,4.25,8.144A4.255,4.255,0,0,1,101.6,90.539Z" />
        </g>
      </svg>
    </SvgIcon>
  );
}

const drawerItems = [
  {
    text: "Jobs ",
    icon: PriceListIcon,
    url: "/dashboard/jobList",
    id: 2
  },
  {
    text: "Payments",
    icon: JobHistoryIcon,
    url: "/dashboard/payments",
    id: 3
  }
];

function DrawerContent() {
  const classes = useStyles();
  const { user } = useContext<UserContextData>(UserContext);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  useEffect(() => {
    // Update the document title using the browser API
    switch (window.location.pathname) {
      case "/dashboard/payments": {
        setSelectedIndex(3);
        break;
      }
      case "/dashboard/jobList": {
        setSelectedIndex(2);
        break;
      }
      case "/dashboard/user": {
        setSelectedIndex(1);
        break;
      }
    }
  });

  const userManagmentInsideList = drawerItems.find(
    x => x.text === "User Management"
  );

  console.log(drawerItems, "drawerItems");
  if (user?.role === UserRoles.ADMIN && !userManagmentInsideList) {
    drawerItems.unshift({
      text: "User Management",
      icon: UserIcon,
      url: "/dashboard/user",
      id: 1
    });
  } else if (user?.role !== UserRoles.ADMIN && userManagmentInsideList) {
    // remove first item since we prepended it earlier
    drawerItems.shift();
  }
  const handleListItemClick = (index: number) => {
    setSelectedIndex(index);
  };

  return (
    <div className={classes.menuList}>
      <div className={classes.logoClass}>
        {/* <img
          src={logo}
          alt="Kitten"
          width="85%"
          className={classes.logoImage}
        /> */}
      </div>

      <Divider />
      <List>
        {drawerItems.map(item => (
          <>
            <ListItem
              button
              key={item.text}
              component={Link}
              onClick={() => handleListItemClick(item.id)}
              to={item.url}
            >
              <div className={classes.menuButtons}>
                <ListItemIcon className={classes.menuButtonsIcon}>
                  {item.id == 1 ? (
                    <UserIcon
                      className={
                        selectedIndex == item.id
                          ? classes.menuButtonsIconPrimary
                          : classes.menuButtonsIcon
                      }
                    />
                  ) : item.id == 2 ? (
                    <PriceListIcon
                      className={
                        selectedIndex == item.id
                          ? classes.menuButtonsIconPrimary
                          : classes.menuButtonsIcon
                      }
                    />
                  ) : item.id == 3 ? (
                    <JobHistoryIcon
                      className={
                        selectedIndex == item.id
                          ? classes.menuButtonsIconPrimary
                          : classes.menuButtonsIcon
                      }
                    />
                  ) : (
                    <JobHistoryIcon
                      className={
                        selectedIndex == item.id
                          ? classes.menuButtonsIconPrimary
                          : classes.menuButtonsIcon
                      }
                    />
                  )}
                </ListItemIcon>
                <Typography
                  variant="body1"
                  // component="body1"
                  align="center"
                  className={
                    selectedIndex == item.id
                      ? classes.iconTextPrimary
                      : classes.iconText
                  }
                >
                  {/* {item.text} */}
                </Typography>
              </div>
            </ListItem>
            <Divider className={classes.listDivider} />
          </>
        ))}

        <Divider />

        {/* <ListItem
          button
          key="Help"
          component={Link}
          onClick={() => handleListItemClick(0)}
          to="/dashboard/help"
        >
          <ListItemIcon>
            <HelpIcon className={classes.iconText} />
          </ListItemIcon>
          <ListItemText className={classes.iconText} primary="Help" />
        </ListItem> */}
      </List>
    </div>
  );
}

const container = window !== undefined ? () => window.document.body : undefined;

export default function Dashboard() {
  const { user ,logoutUser} = useContext<UserContextData>(UserContext);
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  let Redirection = null;
  if (user?.role === UserRoles.ADMIN) {
    Redirection = <Redirect to="/dashboard/jobList" />;
  }

  if (user?.role === UserRoles.USER) {
    Redirection = <Redirect to="/dashboard/jobList" />;
  }

  const isUserAdmin = user?.role === UserRoles.ADMIN;

  const handleDrawerToggle = () => {
    setDrawerIsOpen(!drawerIsOpen);
  };
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CssBaseline />
      {/* fixed app bar */}
      <AppBar position="fixed" className={classes.appBar}>
        
        <Toolbar className={classes.justifyContent}>
        <img
          src={logo}
          alt="Patterson Oil"
          // width="85%"
          className={classes.logoImage}
        />
         
          {IS_PROD=="false" && (
                  <Typography  variant="h5" noWrap className={classes.marginHead}>
                    Job Management Dashboard - Test Environment
                </Typography>
              )}
            {IS_PROD=="true" && (
                  <Typography  variant="h5" noWrap className={classes.marginHead}>
                    Job Management Dashboard
                </Typography>
              )}
           
             
             <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
         
          <UserMenu />
        </Toolbar>
      </AppBar>
      {/* nav is needed to push the content away from the drawer since drawer is above every thing in z-index terms and its position is fixed*/}
      <nav className={classes.drawer}>
        <Hidden smUp implementation="css">
          <Drawer
            open={drawerIsOpen}
            anchor="left"
            container={container}
            onClose={handleDrawerToggle}
            classes={{ paper: classes.drawerPaper }}
            variant="temporary"
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            <DrawerContent />
          </Drawer>
        </Hidden>

        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper
            }}
            variant="permanent"
            open
          >
            <DrawerContent />
          </Drawer>
        </Hidden>
      </nav>

      <main className={classes.content}>
        <Switch>
          <Route exact path="/dashboard">
            {Redirection}
          </Route>
          <Route path="/dashboard/user">
            {isUserAdmin ? <User /> : <Redirect to="/dashboard/jobList" />}
          </Route>
          <Route path="/dashboard/jobList">
            <JobList logoutUser={logoutUser}/>
          </Route>
          <Route path="/dashboard/payments">
            <Payments logoutUser={logoutUser}/>
          </Route>
          <Route path="/dashboard/help">
            <Help />
          </Route>
        </Switch>
      </main>
    </div>
  );
}
