import React from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import TableHead from "@material-ui/core/TableHead";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Input from "@material-ui/core/Input";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import SaveIcon from "@material-ui/icons/Save";
import DeleteIcon from "@material-ui/icons/Delete";
import { axios } from "../../utils/axiosConfig";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
var moment = require("moment");
export default function UsersTable(props) {
  const classes = props.classes;
  //   const { table } = props.table
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [isCellHovered, setCellHover] = React.useState(null);
  const [cellValue, setCellValue] = React.useState(null);
  const [deleteValue, setDeleteValue] = React.useState(null);
  const [updatedData, setDataUpdate] = React.useState([]);
  const [updateTable, setUpdateTable] = React.useState(false);
  const [saveProgress, setSaveProgress] = React.useState(false);
  const [rows, setRows] = React.useState(props.rows);
  const [values, setValues] = React.useState({
    email: "",
    password: "",
    showPassword: false
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  //   let rows=props.rows;
  console.log(props.rows, "props.rows");
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, props.rows.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const cellHover = cellValue => {
    setCellHover(cellValue);
  };
  const cellLeave = cellValue => {
    setCellHover(null);
  };
  const handleCellClick = (row, feild) => {
    console.log(row, "rowrow", feild);
  };

  const handleCellChange = (event, row, feild) => {
    if (!updateTable) {
      setUpdateTable(true);
    }
    setValues({ ...values, email: row.email, password: event.target.value });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const saveData = () => {
    setSaveProgress(true);
    
    let url = "/user/change/password";
    let body = {
      email: values.email,
      newPassword: values.password
    };

    axios.post(url, body).then(res => {
      let response = res.data;
      if (response.success === true) {
        props.showSnackBar("Password Updated");
        props.getAllUsers();
      } else {
        props.showSnackBar("Unable to Update Password");
        props.getAllUsers();
      }
    });
  };

  const deleteRow = row => {
    console.log(row, "updatedDataupdatedDataupdatedData");
    setDeleteValue(row.userID);
    let url = "/user/delete";
    let body = {
        email: row.email,
      };
      axios.post(url, body).then(res => {
        let response = res.data;
        if (response.success === true) {
          props.showSnackBar("User Deleted");
          props.getAllUsers();
        } else {
          props.showSnackBar("Unable to delete User");
          props.getAllUsers();
        }
      });
  };

  return (
    <>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <div className={classes.tableHeading}>
          <Typography>{props.tableName}</Typography>
        </div>

        <Table aria-label="custom pagination table" className={classes.table}>
          <TableHead>
            <TableRow className={classes.tableHead}>
              {props.tableColumns.map(column => (
                <TableCell
                  className={classes.tableHeadCol}
                  variant="head"
                  align="center"
                >
                  {column.title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableHead}>
            {(rowsPerPage > 0
              ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : rows
            ).map(row => (
              <TableRow key={row.name}>
                {props.tableColumns.map(column => (
                  <TableCell
                    className={
                      isCellHovered == row.userID + "-" + column.field &&
                      column.field != "action"
                        ? classes.tableRowHovered
                        : classes.tableRow
                    }
                    onMouseOver={() =>
                      cellHover(row.userID + "-" + column.field)
                    }
                    onMouseLeave={() =>
                      cellLeave(row.userID + "-" + column.field)
                    }
                    component="th"
                    scope="row"
                    align="center"
                    onClick={() => handleCellClick(row, column.field)}
                    // className={classes.tableBody}
                  >
                    {cellValue != row.userID + "-" + column.field &&
                      column.field != "action" && (
                        <div className={classes.tableCellClass}>
                          <Typography
                            style={{
                              margin: "4px"
                            }}
                          >
                            {column.field == "updatedAt" ||
                            column.field == "createdAt"
                              ? moment(row[column.field]).tz('Europe/London').format("LLL")
                              : column.field == "password"
                              ? "********"
                              : row[column.field]}
                          </Typography>

                          {isCellHovered == row.userID + "-" + column.field &&
                            column.field == "password" &&
                            !updateTable && (
                              // true
                              <EditIcon
                                className={classes.editIcon}
                                style={
                                  {
                                    // position: "absolute"
                                    // top: "5px",
                                    // right: "5px",
                                  }
                                }
                                onClick={() =>
                                  setCellValue(row.userID + "-" + column.field)
                                }
                              />
                            )}
                        </div>
                      )}
                    {cellValue == row.userID + "-" + column.field &&
                      column.field != "action" && (
                        <div className={classes.tableCellClass}>
                          <form noValidate autoComplete="off">
                            <Input
                              onChange={event =>
                                handleCellChange(event, row, column.field)
                              }
                              colorSecondary
                              type={values.showPassword ? "text" : "password"}
                              autoFocus
                              defaultValue={row[column.field]}
                              className={
                                isCellHovered == row.userID + "-" + column.field
                                  ? classes.inputFeildHaulageHovered
                                  : classes.inputFeildHaulage
                              }
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                  >
                                    {values.showPassword ? (
                                      <Visibility
                                        className={
                                          isCellHovered ==
                                          row.userID + "-" + column.field
                                            ? classes.iconHovered
                                            : classes.iconSimple
                                        }
                                      />
                                    ) : (
                                      <VisibilityOff
                                        className={
                                          isCellHovered ==
                                          row.userID + "-" + column.field
                                            ? classes.iconHovered
                                            : classes.iconSimple
                                        }
                                      />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              }
                            />
                          </form>
                        </div>
                      )}

                    {column.field == "action" && (
                      <div className={classes.tableCellButtonClass}>
                        <Button
                          variant="contained"
                          className={classes.saveData}
                        //   disabled={deleteValue === row.userID}
                          startIcon={deleteValue !== row.userID &&<DeleteIcon />}
                          onClick={() => deleteRow(row)}
                        >
                          {deleteValue !== row.userID && "Delete"}
                          {deleteValue === row.userID && (
                            <CircularProgress
                              size={20}
                              className={classes.circularProgress}
                            />
                          )}
                        </Button>
                      </div>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}

            {/* {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )} */}
          </TableBody>
          <TableFooter>
            <TableRow
            //   className={classes.tablePagination}
            ></TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <div className={classes.tableFooter}>
        {updateTable && (
          <Button
            variant="contained"
            className={classes.saveData}
            // disabled={saveProgress}
            startIcon={!saveProgress && <SaveIcon />}
            onClick={() => saveData()}
          >
            {!saveProgress && "Save"}
            {saveProgress && (
              <CircularProgress
                size={20}
                className={classes.circularProgress}
              />
            )}
          </Button>
        )}

        <TablePagination
          className={classes.tablePagination}
          rowsPerPageOptions={[5, 10, 25]}
          count={props.rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            native: true
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </div>
    </>
  );
}

const useStyles1 = makeStyles(theme => ({
  tableRoot: {
    flexShrink: 0,
    justifyContent: "space-between",
    marginLeft: theme.spacing(2.5)
  }
}));
function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = event => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = event => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = event => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.tableRoot}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
};
