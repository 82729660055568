import React, { useContext, useState } from "react";
import {
  IconButton,
  Typography,
  Grid,
  Button,
  makeStyles,
  Theme,
  createStyles,
} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import AccountCircle from "@material-ui/icons/AccountCircle";
import { UserContextData, UserContext } from "../../../Contexts/userContext";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

// https://github.com/mui-org/material-ui/issues/11108
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    noHover: {
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    menuButtonsIconPrimary: {
      margin: "auto",
      color: theme.palette.primary.main,
      width: "100%",
      height: "70px"
    },
    menuButton: {
      // marginRight: theme.spacing(2),
      marginLeft: 'auto',
    //   [theme.breakpoints.up("sm")]: {
    //     display: "none"
    //   },
    //  justifyContent:'right'
    },
  })
);
function UserIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
        <g>
          <path d="M94.677,82.929l-5.013-1.074-.018-.006L77.622,79.277A13.7,13.7,0,0,0,77.75,77.5V71.916A25.516,25.516,0,0,0,89.53,50.43V45.88h2.747a1.749,1.749,0,0,0,1.75-1.75v-6a1.749,1.749,0,0,0-1.75-1.75H91.014A26.953,26.953,0,0,0,70.25,10.473V6.5A1.749,1.749,0,0,0,68.5,4.75h-9A1.75,1.75,0,0,0,57.75,6.5v3.973A26.953,26.953,0,0,0,36.986,36.38H35.723a1.749,1.749,0,0,0-1.75,1.75v6a1.749,1.749,0,0,0,1.75,1.75H38.47v4.55A25.516,25.516,0,0,0,50.25,71.916V77.5a13.7,13.7,0,0,0,.128,1.777L38.354,81.849l-.013,0-5.016,1.074A13.816,13.816,0,0,0,22.44,96.37V121.5a1.75,1.75,0,0,0,1.75,1.75h79.62a1.75,1.75,0,0,0,1.75-1.75V96.37A13.815,13.815,0,0,0,94.677,82.929ZM87.514,36.38H79.25V26.13a1.75,1.75,0,1,0-3.5,0V36.38h-5.5V14.057A23.478,23.478,0,0,1,87.514,36.38ZM61.25,8.25h5.5V36.38h-5.5Zm-3.5,5.808V36.38h-5.5V26.13a1.75,1.75,0,1,0-3.5,0V36.38H40.486A23.476,23.476,0,0,1,57.75,14.058ZM37.473,39.88H90.527v2.5H37.473Zm4.5,10.55V45.88H86.03v4.55a22.03,22.03,0,0,1-44.06,0ZM64,75.95A25.394,25.394,0,0,0,74.25,73.8v3.7a10.25,10.25,0,0,1-20.5,0V73.8A25.394,25.394,0,0,0,64,75.95ZM25.94,96.37a10.3,10.3,0,0,1,8.117-10.019l2.913-.624V119.75H25.94ZM40.47,84.978l10.8-2.312a13.774,13.774,0,0,0,10.984,8.46V119.75H40.47ZM65.75,119.75V91.126a13.772,13.772,0,0,0,10.984-8.46l10.8,2.312V119.75Zm36.31,0H91.03V85.728l2.914.624A10.3,10.3,0,0,1,102.06,96.37Z" />
          <path d="M97.81,102.058a1.749,1.749,0,0,0-1.75,1.75v6.255a1.75,1.75,0,0,0,3.5,0v-6.255A1.75,1.75,0,0,0,97.81,102.058Z" />
          <path d="M55.86,98.08h-9a1.75,1.75,0,0,0,0,3.5h9a1.75,1.75,0,0,0,0-3.5Z" />
        </g>
      </svg>
    </SvgIcon>
  );
}

export default function UserMenu() {
  const { user, logoutUser } = useContext<UserContextData>(UserContext);
  const classes = useStyles();

  console.log(user?.lastName);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div  className={classes.menuButton}>
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleClick}
        color="inherit"
       
      >
        <UserIcon/>
      </IconButton>

      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem disableRipple={true} className={classes.noHover}>
          <Grid container direction="column">
            <Typography variant="h6">
              {`${user?.firstName ?? ''} ${user?.lastName ?? ''}`}
            </Typography>
            <Typography>{user?.email ?? "default email"}</Typography>
          </Grid>
        </MenuItem>

        <MenuItem onClick={logoutUser}>
          <Typography color="error">Logout</Typography>
        </MenuItem>
      </Menu>
    </div>
  );
}
