import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import Grid from "@material-ui/core/Grid";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import TimelineDot from "@material-ui/lab/TimelineDot";
import moment from "moment-timezone";
import Card from "@material-ui/core/Card";
import FastfoodIcon from "@material-ui/icons/Fastfood";
import LaptopMacIcon from "@material-ui/icons/LaptopMac";
import HotelIcon from "@material-ui/icons/Hotel";
import RepeatIcon from "@material-ui/icons/Repeat";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import InfoIcon from "@material-ui/icons/Info";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import LocationSearchingIcon from "@material-ui/icons/LocationSearching";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import TimerIcon from "@material-ui/icons/Timer";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import FormControl from "@material-ui/core/FormControl";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import BusinessIcon from "@material-ui/icons/Business";
import CheckIcon from "@material-ui/icons/Check";
import CardContent from "@material-ui/core/CardContent";
import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import EditIcon from "@material-ui/icons/Edit";
import HelpOutlinedIcon from "@material-ui/icons/HelpOutlined";
import StarOutlinedIcon from "@material-ui/icons/StarOutlined";
const useStyles = makeStyles(theme => ({
  paper: {
    padding: "16px 16px"
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main
  },
  jobCardButton2: {
    // color:"#f1b640",
    // borderRadius: '60%',
    fontSize: "12px",
    fontWeight: 600
    // "&:hover": {
    //   backgroundColor: '#ffff'
    // },
    // backgroundColor:"#ffff"
  },
  cardInfo: {
    marginTop: "15px"
  },
  tablePagination: {
    // position: 'sticky'
  },
  borderLeft: {
    borderLeft: "1px solid gray"
  },
  borderRight: {
    // borderRight: "1px solid gray",
    // borderTop: "1px solid gray"
  },
  root: {
    background: "rgba(35,35,35, 0.9)",
    padding: "8px"
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  titleDiv: {
    display: "flex"
  },
  status: {
    fontSize: 14,
    color: "#fff",
    marginTop: "2px",
    fontStyle: "italic"
  },
  statusTime: {
    marginTop: 15
  },
  pos: {
    marginBottom: 12
  },
  linearProgress: {
    marginTop: "15px"
  },
  chip: {
    color: "primary",
    height: "23px"
  },
  jobCardTitle: {
    marginTop: 7,
    color: "#fff"
  },
  jobCardPrice: {
    color: "#fff",
    fontSize: "15px",
    // marginTop: "7px",
    marginBottom: "7px"
  },
  icon: {
    color: "#fff",
    margin: "4px 4px"
  },
  oilgasIcon: {
    margin: "auto",
    marginRight: "10px",
    color: "#e8e8e8",
    width: "50px",
    height: "30px"
  },
  editIcon: {
    color: "#fff",
    fontSize: "20px",
    cursor: "pointer",
    marginRight: "20px"
  },
  circularProgress: {
    width: "20px !important",
    height: "20px !important"
  },
  closeIcon: {
    color: "#fff",
    fontSize: "20px",
    cursor: "pointer",
    marginRight: "20px",
    marginTop: "20px"
  },
  center: {
    margin: "auto",
    width: "fit-content",
    display: "flex"
  },
  inputFeild: {
    color: "#fff",
    fontSize: "32px",
    background: "none",
    textAlign: "center",
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none"
    }
  },
  jobCardButton: {
    color: "#fff",
    // borderRadius: '60%',
    fontSize: "12px",
    "&:hover": {
      backgroundColor: "#f1b640"
    }
  }
}));

export default function JobCancel(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [timeline, setTimeline] = React.useState([]);
  useEffect(() => {
    setTimeline(props.cancelJobs);
  }, [props.cancelJobs]);

  return (
    <Timeline align="left">
      {timeline &&
        timeline.map((item, index) => (
          <Timeline align="alternate">
            <Card className={classes.root}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className={classes.borderRight}
                >
                  <div className={classes.center}>
                    <Typography
                      variant="h5"
                      component="h5"
                      className={classes.jobCardTitle}
                    >
                      Driver :{" "}
                      {
                        props.allDrivers.find(x => x.driverID === item.driverID)
                          .driverName
                      }
                    </Typography>{" "}
                  </div>
                  {item.acceptedAt !== null && (
                    <div className={classes.center}>
                      <Typography
                        variant="p"
                        component="p"
                        className={classes.jobCardTitle}
                      >
                        AcceptedAt At:{" "}
                        {moment(item.acceptedAt)
                          .tz("Europe/London")
                          .format("YYYY-MM-DD HH:mm")}
                      </Typography>{" "}
                    </div>
                  )}
                  {item.cancelledAt !== null && (
                    <div className={classes.center}>
                      <Typography
                        variant="p"
                        component="p"
                        className={classes.jobCardTitle}
                      >
                        Cancelled At:{" "}
                        {moment(item.cancelledAt)
                          .tz("Europe/London")
                          .format("YYYY-MM-DD HH:mm")}
                      </Typography>{" "}
                    </div>
                  )}

                  {item.cancelMethod && (
                    <div className={classes.center}>
                      <Typography
                        variant="p"
                        component="p"
                        className={classes.jobCardTitle}
                      >
                        Cancelled Method :{item.cancelMethod}
                      </Typography>{" "}
                    </div>
                  )}

                  <div className={classes.center}>
                    <Typography
                      variant="p"
                      component="p"
                      className={classes.jobCardTitle}
                    >
                      Comments : {item.comments}
                    </Typography>{" "}
                  </div>
                </Grid>
              </Grid>
            </Card>
            {index !== timeline.length - 1 && (
              <TimelineItem className={classes.separator}>
                <TimelineSeparator>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent
                  className={classes.separator}
                ></TimelineContent>
              </TimelineItem>
            )}
          </Timeline>
        ))}
    </Timeline>
  );
}
